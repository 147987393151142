@for (bookmark of bookmarks; track bookmark.id) {
  <flow-menu-main-bookmark
    [bookmark]="bookmark"
    [routerLink]="['/solutions', bookmark.id]"
    [routerLinkActive]="'current-menu-item'"
    [label]="bookmark.name"
    [iconClass]="'flow-icon'"
    [icon]="bookmark.id"
    [boughtProducts]="boughtProducts"
  />
}
