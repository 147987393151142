@if (helpPanelVisible$ | async; as helpPanelVisible) {
  <div [ngClass]="{ 'header__open': helpPanelVisible === 'yes' }">
    @if (!isStandardEinvoicingProject) {
      <flow-menu-top [helpPanelVisible]="helpPanelVisible" />
    }
    <!--
    @if (helpPanelVisible === 'yes') {
      <flow-help />
    }
    -->
    <flow-menu-main />
  </div>
}
