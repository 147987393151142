/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { FlowAppInterface, FlowSsoCompanyInterface, FlowUserInterface } from '../../interfaces';
import { FlowHelpers, FlowUtilsService, FlowIdLabelInterface } from '@flow/core';

@Injectable({
  providedIn: 'root'
})
export class FlowUserService {

  /* vendorDescription is the company NAME (aka the human text instead of the id) of the current domain the user is on.
  *   e.g. on gemeenteamsterdam.flow the vendorDescription would be "Gemeente Amsterdam" */
  private _vendorDescription = '';

  /** Subject to store and check current user Object */
  private _user: FlowUserInterface;

  /** Subject to store and check logged in status */
  private _isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _dgProducts = [
    'emaca',
    'shosy',
    'prosy',
    'socsy',
    'ppcca',
    'campr',
    'asssy',
    'marco',
    'solsy',
    'sarec',
    'leadgen',
    'inlsy',
    'event',
    'training',
    'camdash',
    'vmktplc'
  ];

  constructor(
    private UtilsService: FlowUtilsService
  ) { }

  get language(): string {
    return this.getExtraValue('UserLanguage');
  }

  get user(): FlowUserInterface {
    return this._user;
  }

  get isFlowUser(): boolean {
    return this.company.toLowerCase() === 'flow';
  }

  get fullName(): string {
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  get app(): FlowAppInterface {
    return this.user.app;
  }

  get company(): string {
    return this.user.platform;
  }

  get customer(): FlowSsoCompanyInterface {
    return this.user.customer;
  }

  get username(): string {
    return this.user.username;
  }

  get roles(): string[] {
    return this.user.roles;
  }

  get hasPartners(): boolean {
    return this.user.hasPartners;
  }

  get boughtProducts(): string[] {
    return this.user.acquiredProducts;
  }

  get boughtPartnerProducts(): string[] {
    return this.user.partnerProducts || [];
  }

  get defaultCurrency(): string {
    return this.user.app.defaultCurrency;
  }

  get defaultLanguage(): string {
    return this.user.app.defaultLanguage;
  }

  get selectedCurrencies(): string[] {
    return this.user.app.selectedCurrencies;
  }

  get selectedLanguages(): string[] {
    return this.user.app.selectedLanguages;
  }

  get companyInformation(): FlowSsoCompanyInterface {
    return this.user.customer;
  }

  get vendorDescription(): string {
    return this._vendorDescription;
  }

  /** Sets current user value and current logged in status */
  set(value: FlowUserInterface, status = false): void {
    this._user = value;
    this._isLoggedIn$.next(status);

    /*
      Check if we need to set or clear the _vendorDescription.
      NOTE:
        this logic is here because there are 2 /me calls, one with and one without the "source" queryParam. Only the response of the one
        with the source queryParam will include the vendorDescription. However both response are treat as same and saved a this._user.
        Meaning one response will override the other and therefore the "vendorDescription" might not be there. To counter this we save the
        vendorDescription to it's own local _vendorDescription variable when present and only clear it when user logout.
    */
    if (status) {
      if (value && value.vendorDescription !== undefined) {
        this._vendorDescription = value.vendorDescription;
      }
    }
    else {
      this._vendorDescription = '';
    }
  }

  /** Return current user asynchronously */
  get$(): Observable<FlowUserInterface> {
    return of(this._user);
  }

  /** Gets a property from the main user object */
  getProperty(property: string): any {

    if (!property) {
      return false;
    }

    if (!this.UtilsService.hasProperty(this.user, property)) {
      throw new TypeError(`FLowUserService: property ${property} not found in the user object.`);
    }

    return this.user[property];
  }

  /** Gets a Value from the Extra array of Objects */
  getExtraValue(key: string, property?: string): any {

    if (!this.UtilsService.hasProperty(this.user, 'extra')) {
      return false;
    }

    return this.UtilsService.getExtraValue(this.user.extra, key, property);
  }

  /**
   * Returns an array of selected language objects sorted by language name
   */
  getSelectedLanguageNames(): FlowIdLabelInterface[] {
    const selectedLanguageNames = [];

    this.selectedLanguages.forEach(code => {
      selectedLanguageNames.push({
        id: code,
        label: this.UtilsService.getLanguageName(code)[0]
      });
    });

    return selectedLanguageNames.sort((a, b) => a.label.localeCompare(b.label));
  }

  /**
   * Checks if user has bought given product
   */
  isBoughtProduct(productKey: string): boolean {
    if (!productKey) {
      return false;
    }

    return !!this.boughtProducts.find(product => product === productKey);
  }

  /**
   * Checks if user is a customer user
   */
  isCustomer(): boolean {
    return FlowHelpers.vendorName(this.company) !== 'Flow';
  }

  /**
   * Checks if current project is DG Project
   */
  isDgProject(): boolean {
    return this.boughtProducts.some(product => this._dgProducts.indexOf(product) > -1);
  }

  /** Returns logged in status */
  isLoggedIn(): boolean {
    return this._isLoggedIn$.getValue();
  }

  /** Returns logged in status as observable */
  isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  /** Checks if current user is acting as a partner */
  isActingAsPartner(): boolean {
    return this.user.partner;
  }

  /** Checks if current user is acting as a vendor */
  isActingAsVendor(): boolean {
    return !this.user.partner;
  }

  isSuperAdmin(app = 'portal') {
    return this.hasPermission([`${app}:super-admin`]);
  }

  isAdmin(app = 'portal') {
    return this.hasPermission([`${app}:admin`]);
  }

  isWriter(app = 'portal') {
    return this.hasPermission([`${app}:writer`]);
  }

  isSuperReader(app = 'portal') {
    return this.hasPermission([`${app}:super-reader`]);
  }

  isReader(app = 'portal') {
    return this.hasPermission([`${app}:reader`]);
  }

  hasRole(app = 'portal', role = 'admin') {
    return this.hasPermission([`${app}:${role}`]);
  }

  /**
   * Checks if the user has given capabilitie(s).
   */
  hasPermission(capabilities: string[]): boolean {
    if (!FlowHelpers.isObject(this.user)) {
      return false;
    }

    if (!FlowHelpers.isEmptyArray(capabilities)) {
      return capabilities.some(capability => this.user.roles.indexOf(capability) > -1);
    }

    return false;
  }
}
