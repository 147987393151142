/**
 * Based on https://pivan.github.io/ngx-color-picker/
 */

import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { Color, ColorPickerControl } from '@iplab/ngx-color-picker';

@Component({
  selector: 'flow-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowColorPickerComponent implements OnInit {
  private _color: Color = null;

  labels: FlowTranslateLabel;

  colorControl = new ColorPickerControl().hidePresets();

  isVisible = false;

  @Input() set color(color: string) {
    this.colorControl.setValueFrom(color);
    this._color = this.colorControl.value;
  }

  @Output() colorChange: EventEmitter<string> = new EventEmitter();

  @HostListener('click', ['$event']) showColorPicker() {
    if (this.isVisible === true) {
      return;
    }

    this.isVisible = !this.isVisible;
  }

  @HostBinding('style.background-color') get background(): string {
    return this._color ? this._color.toHexString() : null;
  }

  constructor(private TranslateService: FlowTranslateService) {}

  ngOnInit(): void {
    this.labels = this._translateLabels();
  }

  applyColor(event: MouseEvent): void {
    event.stopPropagation();

    this._color = this.colorControl.value;
    this.isVisible = false;

    this.colorChange.emit(this.colorControl.value.toHexString());
  }

  discard(event: MouseEvent): void {
    event.stopPropagation();

    this.isVisible = false;
  }

  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.btn.ok',
      'general.btn.cancel',
    ]);
  }
}
