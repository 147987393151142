/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { FlowTokensService } from '../services/tokens/tokens.service';
import { FlowAuthService } from '../services/auth/auth.service';
import { FlowEnvService, FlowModelsService, FlowRouterService } from '@flow/core';
import { FlowUserService } from '../services/user/user.service';

const IGNORE_TOKEN_FROM_URL = [
  '/signOut',   // BE still returns a valid token when FE is already logged out, so we need to ignore them
];

@Injectable()
export class FlowAuthRequestInterceptor implements HttpInterceptor {

  private _ignoreTokenFromUrlRegex;

  constructor(
    private EnvService: FlowEnvService,
    private ModelsService: FlowModelsService,
    private AuthService: FlowAuthService,
    private UserService: FlowUserService,
    private TokensService: FlowTokensService,
    private RouterService: FlowRouterService
  ) {
    const textBlockModel = ModelsService.getModel('TextBlock'); // com.tiekinetix.kinetix.shop.model.KinetixTextBlock

    const arrIgnoreTokenFromUrl = [
      ...IGNORE_TOKEN_FROM_URL,
      textBlockModel,   // based on the scenarios from ST032140 & ST032627, translation endpoints will at one point return a different
                        // tokens that the ones in the requestHeader, causing session issue. This most likely related that these (heavy)
                        // calls are cached on the CDN and the client. Since translation r not really userData, we can safely ignore any
                        // tokens they return.
    ];

    this._ignoreTokenFromUrlRegex = new RegExp(arrIgnoreTokenFromUrl.join('|'), 'g');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipAuthParam = req.params.get('skipAuth');

    if (
      req.url.indexOf(this.EnvService.get('cmsUrl')) ===  -1
      &&
      req.url.indexOf(this.EnvService.get('tpmUrl')) ===  -1
    ) {
      return next.handle(req);
    }

    // If the request is public and should skip Authorization;
    if (
        (skipAuthParam && skipAuthParam === 'true')
        ||
        (req.body && req.body.skipAuth)
       ) {

      if (req.body && req.body.skipAuth) {
        delete req.body.skipAuth;
      }

      return next.handle(req.clone({
        params: (skipAuthParam === 'true') ? req.params.delete('skipAuth') : req.params
      }));
    }

    const tokens = this.TokensService.getTokens();
    const headers = {
      'AuthorizationFlow': `${this.TokensService.tokenStrategy}${tokens.token}`,
      'AuthorizationFlow-Refresh': tokens.refreshToken
    };

    // console.warn('%c______ngAuth interceptor tokens', 'color:magenta', tokens, req.url);

    return next.handle(req.clone({
      setHeaders: headers
    })).pipe(
      tap(
        (event: HttpResponse <any>) => {
          if (event.headers) {
            // console.warn('______ngAuth WHEN event.headers PRE setTokens', );

            if (!event.url.match(this._ignoreTokenFromUrlRegex)) {
              // console.warn('%c______ngAuth WHEN event.headers PRE setTokens event=', 'color:springgreen', event);

              this.TokensService.setTokens(
                event.headers.get('authorizationflow'),
                event.headers.get('authorizationflow-refresh')
              );
            }
          }
        }
      ),
      catchError(err => {
        if (err instanceof HttpErrorResponse &&
          err.status === 401 &&
          this.UserService.isLoggedIn()) {
          this.RouterService.navigate(this.AuthService.logoutUrl);
        }
        return throwError(err);
      })
    );
  }
}
