<mat-dialog-content class="dialog-content">
  <iframe [src]="data.url | safe:'resourceUrl'" width="100%" height="100%" frameBorder="0"></iframe>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    class="button"
    [mat-dialog-close]="false">
    {{ data.labels['supportform.btn.close'] }}
  </button>
</mat-dialog-actions>
