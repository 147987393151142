@if (helpPanelVisible$ | async; as helpPanelVisible) {
  <div [ngClass]="{ 'header__open': helpPanelVisible === 'yes' }">
    <flow-menu-top [helpPanelVisible]="helpPanelVisible"></flow-menu-top>
    <!-- <flow-help *ngIf="helpPanelVisible === 'yes'"></flow-help> -->
    @if (isStandardEinvoicingProject) {
      <div class="einvoicing-title">{{ browserTitle }}</div>
    }
    <flow-menu-main></flow-menu-main>
  </div>
}
