import { Component, ChangeDetectionStrategy, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'flow-custom-help-site-dialog',
  templateUrl: './custom-help-site-dialog.component.html',
  styleUrls: ['./custom-help-site-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowCustomHelpSiteDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
