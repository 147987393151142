import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';

import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FlowDmcAssetsLoaderService } from '../services/dmc-assets-loader.service';

export const FlowDmcAssetsLoaderResolver: ResolveFn<boolean> = (route, state) => {
  const DmcAssetsLoaderService = inject(FlowDmcAssetsLoaderService);

  return from(
    Promise.all([
      DmcAssetsLoaderService.loadStyles(),
      DmcAssetsLoaderService.loadScript()
    ])
  )
  .pipe(
    map(() => true),
    catchError((error) => {
      console.error(error);
      return of(true);
    })
  );
};
