<div class="gateway-bg"></div>
<div class="container br-3 border-1 border-solid border-gray mb-5 gateway-panel">

  <div class="text-right pos-absolute" style="right: 0;">
    <a routerLink="/auth/logout" class="logoutLink button text-color-blue">
      <i class="material-icons color-danger">power_settings_new</i>
      {{ labels['general.label.logout'] }}
    </a>
  </div>

  <div class="content-wrapper text-center">

    <!--flow-logo-->
    <div class="flow-logo"></div>

    <h3 class="pb-1 text-color-blue">{{ labels['login.label.gateway_destination'] }}</h3>
    <p class="text-color-blue">{{ labels['login.label.gateway_select_destination'] }}</p>

    <div class="d-flex justify-content-center">

      <div>
        <ng-container *ngIf="showOnboarding">
          <p class="text-color-blue pt-5 mb-3" [innerHTML]="onboarding.heading"></p>
          <a (click)="onboard()">
            <div class="list-item-with-arrow">
              <div class="item">
                <div class="title" [innerHTML]="onboarding.label"></div>
                <!-- jv: no url for company onboarding
                <small class="color-gray">{{ onboarding.url }}</small>
                -->
              </div>
              <i class="material-icons color-gray ml-5">arrow_forward</i>
            </div>
          </a>
        </ng-container>

        <div *ngIf="showOnboardingSuccessMsg" class="d-flex justify-content-center color-success">
          <i class="material-icons mr-1">check_circle</i>
          <div class="text-center w-60">{{ labels['login.label.gateway_onboard_admin_notified'] }}</div>
        </div>

        <div *ngIf="showOnboardingErrorMsg" class="d-flex justify-content-center">
          <div class="text-center w-60 color-danger">{{ labels['error.general.error_loading'] }}</div>
        </div>

        <ng-container *ngIf="projects?.length">
          <p class="text-color-blue pt-5 mb-3"
             [innerHTML]="labels['login.label.gateway_goto_vendor']"
          ></p>
          <a *ngFor="let project of projects" (click)="goto(project.url)">
            <div class="list-item-with-arrow">
              <div class="item">
                <div class="title">{{ project.id }}</div>
                <small>{{ project.url }}</small>
              </div>
              <i class="material-icons color-gray ml-5">arrow_forward</i>
            </div>
          </a>
        </ng-container>

        <ng-container *ngIf="ownProject">
          <p class="text-color-blue pt-5 mb-3" [innerHTML]="ownProject.heading"></p>
          <a (click)="goto(ownProject.url)">
            <div class="list-item-with-arrow">
              <div class="item">
                <div class="title">{{ ownProject.id }}</div>
                <small>{{ ownProject.url }}</small>
              </div>
              <i class="material-icons color-gray ml-5">arrow_forward</i>
            </div>
          </a>
        </ng-container>

        <!--
        <ng-container *ngIf="becomeOurCustomer">
          <p class="text-color-blue pt-5 mb-3" [innerHTML]="labels['login.label.gateway_goto_webshop']"></p>
          <a (click)="gotoWebshop()">
            <div class="list-item-with-arrow">
              <div class="item">
                <div class="title">{{ labels['login.label.gateway_visit_webshop'] }}</div>
              </div>
              <i class="material-icons color-gray ml-5 my-2">arrow_forward</i>
            </div>
          </a>
        </ng-container>
        -->

        <div *ngIf="showRedirectErrorMsg" class="d-flex justify-content-center">
          <div class="text-center w-60 color-danger">{{ labels['error.general.error_loading'] }}</div>
        </div>
      </div>

    </div>

    <flow-abs-preloader *ngIf="isLoading"></flow-abs-preloader>

    <div class="mt-5 d-flex justify-content-center" *ngIf="isFlowUser">

      <button
        type="button"
        class="button text-center px-4 py-3 mx-2 tile-btn flow-admin-btn"
        (click)="gotoFlowAdmin()"
      >
        <i class="d-block"></i>
        <span class="text-color-blue">Flow ADMIN</span>
      </button>

    </div>

  </div>

  <div class="content-wrapper text-center mt-4" *ngIf="isFlowUser">

    <h3 class="pb-1 text-color-blue">{{ labels['login.label.gateway_impersonation'] }}</h3>
    <p class="text-color-blue">{{ labels['login.label.gateway_select_impersonation'] }}</p>

    <p class="color-danger pt-3" *ngIf="impersonationError">{{ labels['login.label.gateway_impersonation_error'] }}</p>

    <div class="mt-4 mb-2 d-flex justify-content-center">

      <button
        type="button"
        class="button text-center px-4 py-3 mx-2 tile-btn impersonation-btn"
        (click)="impersonate('vendor')"
      >
        <i class="flow-vendor-icon d-block p-0 mt-2 mb-2"></i>
        <span class="text-color-blue">{{ labels['login.label.gateway_impersonate_vendor'] }}</span>
      </button>

      <button
        type="button"
        class="button text-center px-4 py-3 mx-2 tile-btn impersonation-btn"
        (click)="impersonate('partner')"
      >
        <i class="flow-partner-icon d-block p-0 mt-2 mb-2"></i>
        <span class="text-color-blue">{{ labels['login.label.gateway_impersonate_partner'] }}</span>
      </button>

    </div>

  </div>
</div>

<flow-footer [showLinks]="false"></flow-footer>
