<flow-header id="header" />
<router-outlet />

@if (isMobile && isStandardEinvoicingProject) {
  <div id="footer-actions">
    <flow-menu-top class="w-100" />
  </div>
}

<flow-footer />
