import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal
} from '@angular/core';

@Component({
  selector: 'flow-dmc-preloader',
  templateUrl: './dmc-preloader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowDmcPreloaderComponent implements OnInit {

  @Input() alertMessageClassNames?: string;
  @Input() loadingWrapperClassNames?: string;

  isLoading$ = signal<boolean|string>(true);

  ngOnInit(): void {
    this._waitForDmComponent((loading) => this.isLoading$.set(loading));
  }

  private _isDmComponentAvailable(): boolean {
    const dmDocumentsComponent = document.querySelector(
      '.flow-portal-wrapper'
    );

    return !!dmDocumentsComponent;
  }

  private _waitForDmComponent(
    notifyFn: (loading: boolean|string) => void,
    interval = 500,
    maxAttempts = 30
  ): void {
    let attempts = 0;
    let timeoutId: any;

    const checkForAvailability = () => {
      attempts++;
      const isLoading = !this._isDmComponentAvailable();

      // Loading is complete
      if (!isLoading) {
        notifyFn(false);  // Notify success
        clearTimeout(timeoutId);
        return;
      }

      // Maximum attempts reached
      if (attempts >= maxAttempts) {
        notifyFn('error'); // Notify failure
        clearTimeout(timeoutId);
        return;
      }

      // Continue polling
      timeoutId = setTimeout(checkForAvailability, interval);
    };

    checkForAvailability();
  }
}
