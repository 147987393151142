import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { AppComponent } from './app.component';

// environment
import { environment } from '../environments/environment';

// Angular
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { InlineStylesCSPModule } from './modules/inline-styles-csp/inline-styles-csp.module';

// FLOW
import { FlowCoreModule } from '@flow/core';
import { FlowAuthModule } from '@flow/auth';
import { FlowTranslateModule } from '@flow/translate';

import {
  FlowInitService,
  FlowMaterialModule,
  FlowViewsModule,
  FlowModalsModule,
  FlowStatusMessageModule,
} from '@flow/shared';

import { FlowDatatableModule } from '@flow/datatable';

// NgRx
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { reducers, effects } from './store';

import { AppRoutingModule } from './app-routing.module';
import { FlowLayoutModule } from './modules/layout/layout.module';

// NgRx Guards for static data.
import { FlowProductsLoaderGuard } from './guards/products.guard';
import { FlowCategoriesLoaderGuard } from './guards/categories.guard';

// Portal Guard
import { FlowDashboardsGuard } from './guards/dashboards.guard';
import { FlowMarketplaceProfileModule } from './modules/profile/profile.module';
import { FlowApplicationReadyGuard } from './guards/app-ready.guard';
import { FlowWidgetsLoaderGuard } from './guards/widgets.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { FlowMarketplaceSharedModule } from './modules/marketplace-shared/marketplace-shared.module';
import { FlowProjectAccessModule } from './modules/project-access/project-access.module';
import { FlowGatewayModule } from './modules/gateway/gateway.module';
import { FlowPasswordExpiredModule } from './modules/password-expired/password-expired.module';
import { FlowEnforced2FaModule } from './modules/enforced-2fa/enforced-2fa.module';
import { FlowMissingInfoModule } from './modules/missing-info/missing-info.module';
import { FlowSupportModule } from './modules/support/support.module';
import { FlowDisclaimerModule } from './modules/disclaimer/disclaimer.module';

// Freeze store on dev.
export const metaReducers: MetaReducer<any>[] = environment.local
  ? [storeFreeze]
  : [];

export const flowMarketplaceInit = (InitService: FlowInitService) => () =>
  InitService.initialize();

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    InlineStylesCSPModule,
    FlowCoreModule.forRoot({
      env: environment,
    }),
    FlowTranslateModule,
    FlowAuthModule.forRoot({
      env: environment,
      afterLoginRedirect: '/dashboard',
      isMarketplace: true, // Set a variable for the custom auth redirect to prevent from redirecting in other apps.
    }),
    FlowMaterialModule.forRoot(),
    FlowViewsModule,
    FlowModalsModule,
    FlowDatatableModule.forRoot(),
    FlowMarketplaceSharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    EffectsModule.forRoot(effects),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    AppRoutingModule,
    FlowMarketplaceProfileModule,
    FlowLayoutModule,
    FlowStatusMessageModule,
    FlowProjectAccessModule,
    FlowGatewayModule,
    FlowPasswordExpiredModule,
    FlowEnforced2FaModule,
    FlowMissingInfoModule,
    FlowSupportModule,
    FlowDisclaimerModule,
    ReactiveFormsModule,
  ],
  providers: [
    FlowProductsLoaderGuard,
    FlowCategoriesLoaderGuard,
    FlowDashboardsGuard,
    FlowWidgetsLoaderGuard,
    FlowApplicationReadyGuard,
    {
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: flowMarketplaceInit,
      deps: [FlowInitService],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    DecimalPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
