/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  HostListener,
  Output,
  EventEmitter
} from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { FlowRouterService } from '@flow/core';
import { FlowWebsocketNotificationMessageInterface } from '@flow/shared';
import { FlowTranslateLabel } from '@flow/translate';
import { FlowMarketplaceStateInterface, getProductName } from '@marketplaceStore';

import {
  FlowMarketplaceDashboardService
} from '../../../marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';

import {
  FlowNotificationsService
} from '../../../marketplace-shared/services/notifications/notifications.service';

import { FlowSolutionDeepLinkInterface } from '../../../../interfaces';

@Component({
  selector: 'flow-menu-top-notification',
  templateUrl: './menu-top-notification.component.html',
  styleUrls: ['./menu-top-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowMenuTopNotificationComponent implements OnInit {

  @Input() notificationsCount: number;

  @Input() notification: FlowWebsocketNotificationMessageInterface;

  @Input() messagesCount: number;

  @Input() isRead: boolean;

  @Input() currentIndex: number;

  @Input() labels: FlowTranslateLabel;

  @Output() remove: EventEmitter<void> = new EventEmitter();

  @HostBinding('class')
  get classes() {
    let classes = `list-item notification-${this.isRead ? 'read' : 'unread'}`;

    if (!this._isClickable) {
      classes += ' not-clickable';
    }

    return classes;
  }

  productName$: Observable <string>;

  getDefaultReplacementCodes = this.NotificationsService.getDefaultReplacementCodes;
  flattenRootDefaultReplacementCodes = this.NotificationsService.flattenRootDefaultReplacementCodes;

  private _isClickable: boolean;

  constructor(
    private store: Store <FlowMarketplaceStateInterface>,
    private RouterService: FlowRouterService,
    private MarketplaceDashboardService: FlowMarketplaceDashboardService,
    private NotificationsService: FlowNotificationsService
  ) { }

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    // Redirect to document page in document manager if link object with redirect property
    // exists in notification object
    if (this.notification.from === 'manual_document') {
      // ST032926 - DM notifications about new zip archives shouldn't be clickable
      if (this._isClickable) {
        if (this.notification.link) {
          const { module, redirect } = this.notification.link;

          if (module && redirect) {
            const solutionsId = this.MarketplaceDashboardService.getEinvoFilteredSolutionsId({ url: module, key: '' });

            if (solutionsId !== '') {
              const deepLink: FlowSolutionDeepLinkInterface = {
                type: solutionsId,
                url: `page=GeneralDocuments&parameters=dataFileId=${redirect}`
              };

              this.RouterService.navigate(['/solutions', solutionsId], {
                state: { deepLink }
              });
            }
            else {
              this.RouterService.navigate(['/solutions', this.notification.icon]);
            }
          }
          else {
            this.RouterService.navigate(['/solutions', this.notification.icon]);
          }
        }
        else {
          this.RouterService.navigate(['/solutions', this.notification.icon]);
        }
      }
      else {
        // Don't trigger the click to keep the notifications list open
        event.stopPropagation();
      }
    }
    else {
      this.RouterService.navigate(['/solutions', this.notification.icon]);
    }
  }

  ngOnInit(): void {
    this.productName$ = this.store.pipe(select(getProductName, this.notification.icon));

    // ST032926 - DM notifications about new zip archives shouldn't be clickable
    this._isClickable = !this.notification.message.toLowerCase().includes('zip');
  }

  /**
   * Trigger removal of notification
   */
  onRemoveClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.remove.emit();
  }

  /**
   * Mark selected notification and the ones below
   */
  onMouseOver(index: number): void {
    this._toggleMarkNotification(true, index);
  }

  /**
   * Unmark selected notification and the ones below
   */
  onMouseOut(index: number): void {
    this._toggleMarkNotification(false, index);
  }

  /**
   * Toggle of marking selected notifications
   */
  private _toggleMarkNotification(show: boolean, index: number): void {
    const listElement = document.getElementById('notifications-list');
    const selectedClassNames = [];

    for (let i = index; i < this.notificationsCount; i++) {
      selectedClassNames.push(`.notification-idx-${i}`);
    }

    const selectedElements = listElement.querySelectorAll(selectedClassNames.join(','));

    selectedElements.forEach(item => {
      if (show) {
        item.classList.add('to-remove');
      }
      else {
        item.classList.remove('to-remove');
      }
    });
  }
}
