import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';

import { FlowModelsService, FlowProductModelInterface } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateService } from '@flow/translate';

import * as fromActions from '../actions/product.actions';

@Injectable()
export class ProductEffect {

  load$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType<fromActions.FlowProductsActions>(fromActions.ProductsActionTypes.LoadProducts),
      pluck('payload'),
      switchMap(() => {
        const queryParams = {
          platform: 'Flow',
          sort: 'name',
          asc: true,
          query: `{ "active": { "$eq": true } }`,
        };

        return this.ModelsService.list<FlowProductModelInterface>('SimpleProduct', queryParams).pipe(
          map(result => {
            // Products to be returned
            const products = [];

            const currentUser = this.UserService.user;
            const projects = [...new Set(currentUser.vendors.map(project => project.toLowerCase()))];
            const hasPlatform = projects.find(project => project === currentUser.platform.toLowerCase());

            // Remove all non V2 products
            const v2Products = result.data.filter(product => product.extra.find(extra => extra.fieldName === 'v2Product'));

            if (!hasPlatform) {
              projects.push(currentUser.platform.toLowerCase());
            }

            // Handle whitelisting and blacklisting of products
            v2Products.forEach(product => {
              if (product.active) {
                let showProduct = true;

                const filterType = product.extra.find(productExtra => productExtra.fieldName === 'whitelistCompany' ||
                  productExtra.fieldName === 'blacklistCompany' ||
                  productExtra.fieldName === 'whitelistCustomerProjects' ||
                  productExtra.fieldName === 'blacklistCustomerProjects');

                if (filterType) {
                  const valueToSearchIn = filterType.value.split(',').map(item => item.trim().toLowerCase());

                  let whitelist = [];
                  let blacklist = [];

                  switch (filterType.fieldName) {
                    case 'whitelistCompany':
                      showProduct = false;
                      whitelist = valueToSearchIn.filter(company => currentUser.platform.toLowerCase() === company);
                      showProduct = whitelist.length >= 1;
                      // console.log('should show product for whitelistCompany?', valueToSearchIn, currentUser.platform.toLowerCase(), showProduct, product);
                      break;

                    case 'blacklistCompany':
                      showProduct = true;
                      blacklist = valueToSearchIn.filter(company => currentUser.platform.toLowerCase() === company);
                      showProduct = blacklist.length === 0;
                      // console.log('should show product for blacklistCompany?', valueToSearchIn, currentUser.platform.toLowerCase(), showProduct, product);
                      break;

                    case 'whitelistCustomerProjects':
                      showProduct = false;
                        whitelist = valueToSearchIn.filter(company => projects.indexOf(company) !== -1);
                        showProduct = whitelist.length >= 1;
                        // console.log('should show product for whitelistCustomerProjects?', valueToSearchIn, projects, showProduct, product);
                      break;

                    case 'blacklistCustomerProjects':
                      showProduct = true;
                      blacklist = valueToSearchIn.filter(company => projects.indexOf(company) !== -1);
                      showProduct = blacklist.length === 0;
                      // console.log('should show product for blacklistCustomerProjects?', valueToSearchIn, projects, showProduct, product);
                      break;
                  }

                  if (showProduct) {
                    product.boughtByCurrentUser = this.UserService.getProperty('acquiredProducts').indexOf(product.id) > -1;
                    product.boughtByPartnerUser = this.UserService.boughtPartnerProducts.indexOf(product.id) > -1;
                    product.name = this.TranslateService.translateModel('flow', 'SimpleProduct', product.id, 'name') || product.name;

                    products.push(product);
                  }
                }
                else {
                  product.boughtByCurrentUser = this.UserService.getProperty('acquiredProducts').indexOf(product.id) > -1;
                  product.boughtByPartnerUser = this.UserService.boughtPartnerProducts.indexOf(product.id) > -1;
                  product.name = this.TranslateService.translateModel('flow', 'SimpleProduct', product.id, 'name') || product.name;

                  products.push(product);
                }
              }
            });

            return new fromActions.LoadProductsSuccess({
              total: products.length,
              data: products
            });
          }),
          catchError(err => of(new fromActions.LoadProductsFail(err)))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private ModelsService: FlowModelsService,
    private TranslateService: FlowTranslateService,
    private UserService: FlowUserService
  ) {}
}
