import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileIcon'
})
export class FlowFileIconPipe implements PipeTransform {

  transform(value: string): string {
    let icon = 'description';

    if (value) {
      const fileType = value.split('/')[0];
      (fileType === 'image') && (icon = 'image');
    }

    return icon;
  }
}