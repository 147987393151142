<div class="d-flex align-items-center justify-content-between py-2 px-3 modal-title bg-primary color-white">
  <h5 class="truncate" [innerHTML]="labels['dashboard.label.add_widgets']"></h5>
  <button
    matTooltipPosition="left"
    [matTooltip]="labels['generic.btn.close']"
    mat-dialog-close
    class="button button-icon button-white-outline button-no-border"
  >
    <i class="material-icons animated rotateIn">close</i>
  </button>
</div>

@if (loading$ | async) {
  <div class="loading-wrapper">
    <flow-preloader [diameter]="36"></flow-preloader>
  </div>
} @else {
  <div class="search-form-wrapper">
    <mat-form-field
      [appearance]="'fill'"
      [formGroup]="searchForm"
      [floatLabel]="'always'"
    >
      <mat-label>{{ labels['dashboard.sidebar.label.search'] }}</mat-label>
      <input
        matInput
        formControlName="search"
        autocomplete="off"
        cdkFocusInitial
      />

      @if (searchForm.get('search').value) {
        <mat-icon
          matSuffix
          style="cursor: pointer"
          (click)="searchForm.get('search').reset()"
        >
          close
        </mat-icon>
      } @else {
        <mat-icon matSuffix>
          search
        </mat-icon>
      }
    </mat-form-field>
  </div>
  <mat-dialog-content>
    <div class="container-fluid">
      <div class="row">
        @for (widget of visibleWidgets; track widget.id;) {
          <div class="col-12 list-item">
            <div class="d-flex align-items-center justify-content-start">
              <div>
                <i class="material-icons">
                  @switch (widget.type) {
                    @case ('chart') {
                      bar_chart
                    }
                    @case ('einvo') {
                      list_alt
                    }
                    @case ('dmComponents') {
                      info
                    }
                    @case ('dmEinvo') {
                      looks_one
                    }
                    @case ('iframe') {
                      code
                    }
                    @case ('link') {
                      link
                    }
                    @case ('list') {
                      bar_chart
                    }
                    @case ('logo') {
                      image
                    }
                    @case ('notifications') {
                      notifications
                    }
                    @case ('omm') {
                      question_answer
                    }
                    @case ('subscriptionStatus') {
                      info
                    }
                    @case ('total') {
                      assignment
                    }
                    @case ('upload') {
                      upload_file
                    }
                    @case ('webcomponent') {
                      ondemand_video
                    }
                    @case ('youtube video') {
                      ondemand_video
                    }
                  }
                </i>
              </div>
              <div class="pl-3">
                <h6>
                  <span [innerHTML]="widget.name"></span>
                </h6>
                @if (widget.description) {
                  <p [innerHTML]="widget.description"></p>
                }
              </div>
            </div>
            <div
              class="absolute-center d-flex align-items-center justify-content-end list-item-hover"
              (click)="addWidget(widget)"
            >
              <button class="button button-white">
                <i class="material-icons">add</i>
                {{ labels['generic.btn.add'] }}
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  </mat-dialog-content>
}
