import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { FlowAuthService } from '@flow/auth';
import { FlowRouterService } from '@flow/core';

import { FlowPortalService } from '../../../../../services/portal/portal.service';

@Component({
  selector: 'flow-authorize',
  templateUrl: './authorize.component.html',
})
export class FlowAuthorizeComponent {

  loading = true;
  authFailed = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private AuthService: FlowAuthService,
    private RouterService: FlowRouterService,
    private PortalService: FlowPortalService,
  ) {
    const { channel } = activatedRoute.snapshot.params; // NOTE: if "channel" route param deprecated, then use this.EnvService.portalDomain
    const { e, s, logoutUrl } = activatedRoute.snapshot.queryParams;

    // clear old user/tokens if any
    AuthService.logout();

    // store logoutUrl
    if (logoutUrl) {
      AuthService.logoutRedirectUrl = logoutUrl;
    }

    // authenticate user
    AuthService.authorize(channel, {e, s})
    .pipe(
      finalize(() => {
        this.loading = false;
      })
    )
    .subscribe((result) => {
      // auth success
      if (result) {
        // Go to logged in route (can be custom or default route)
        RouterService.navigate(PortalService.getCustomRedirectUrl());
      }
      // auth failed
      else {
        this.authFailed = true;
      }
    });
  }
}
