@if (isVisible) {
  <div class="picker">
    <compact-picker [control]="colorControl">
      <div class="buttons">
        <button
          type="button"
          class="button button-primary button-small ml-2"
          (click)="applyColor($event)"
        >
          {{ labels['general.btn.ok'] }}
        </button>
        <button
          type="button"
          class="button button-primary-outline button-small ml-2"
          (click)="discard($event)"
        >
          {{ labels['general.btn.cancel'] }}
        </button>
      </div>
    </compact-picker>
  </div>
  <div class="overlay" (click)="discard($event)"></div>
}
