import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { FlowCustomHelpSiteDialogComponent } from '../components/custom-help-site-dialog/custom-help-site-dialog.component';

import {
  FlowSupportFormData,
  FlowSupportWidgetCustomHelpSite,
  FlowSupportWidgetLabels,
  FlowSupportWidgetOptions,
  FlowSupportWidgetTokens
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FlowSupportFormService {

  private readonly UPLOAD_PATH = 'contactform/submit';

  private dialogRef: MatDialogRef<FlowCustomHelpSiteDialogComponent>;

  constructor(
    private httpClient: HttpClient,
    private _matDialog: MatDialog
  ) { }

  getIfHasCustomHelpSite(widgetOptions: FlowSupportWidgetOptions): boolean {
    const { customHelpSite } = widgetOptions;

    return !!(customHelpSite && customHelpSite.url);
  }

  openCustomHelpSite(customHelpSite: FlowSupportWidgetCustomHelpSite, labels: FlowSupportWidgetLabels, dialogRef?: any): void {
    if (customHelpSite) {
      // Show the custom help site in a modal
      if (customHelpSite.inModal) {
        const { url } = customHelpSite;

        if (url) {
          // Open the custom help site in a modal dialog
          this._simpleUrlPopup(url, 'customHelpSite');
          //this._openCustomHelpSiteDialog(url, labels);

          // Close the support form dialog, otherwise it overlaps the new dialog
          dialogRef && dialogRef.close();
        }
      }
      // Open the custom help site in a new tab (for website and email)
      else {
        window.open(customHelpSite.url, '_blank');
      }
    }
  }

  submitForm(values: FlowSupportFormData, files: File[], tokens: FlowSupportWidgetTokens, env: string): Observable<any> {
    const formData = new FormData();

    // Append form data
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        formData.append(key, values[key]);
      }
    }

    // Append files
    files.forEach(file => formData.append('upload', file));

    // Set headers
    const headers = new HttpHeaders({
      'AuthorizationFlow': `${tokens.token}`,
      'AuthorizationFlow-Refresh': tokens.refreshToken
    });

    // Make the HTTP POST request
    return this.httpClient.post<any>(
      `${ this._getEndpointUrl(env) }/${ this.UPLOAD_PATH }`,
      formData,
      {
        headers
      }
    );
  }

  // Use this method to get the endpoint url in case we vote for angular for the reusable component
  private _getEndpointUrl(env: string): string {
    switch (env) {
      case 'test':
        return 'https://backend-cms-test.tiekinetix.net';

      case 'preaccept':
        return 'https://backend-cms-preaccept.tiekinetix.net';

      case 'acceptance':
        return 'https://backend-cms-accept.tiekinetix.net';

      default:
        return 'https://backend-cms.tiekinetix.net';
    }
  }

  private _openCustomHelpSiteDialog(url: string, labels: FlowSupportWidgetLabels): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        url,
        labels
      },
      panelClass: 'help-site-dialog',
      disableClose: true,
      autoFocus: false
    };

    this.dialogRef = this._matDialog.open(FlowCustomHelpSiteDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  /**
   * Open a centered simple native popup window that shows the content of an url.
   * Used in cases where external urls are being blocked from showing in an iframe.
   */
  private _simpleUrlPopup(url: string, windowName: string) {
    const popupWidth = window.screen.width * 0.7;
    const popupHeight = window.screen.height * 0.7;

    const top = window.top.outerHeight / 2 + window.top.screenY - (popupHeight / 2);
    const left = window.top.outerWidth / 2 + window.top.screenX - (popupWidth / 2);

    return window.open(
      url,
      windowName,
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,' +
      'width=' +
      popupWidth +
      ',height=' +
      popupHeight +
      ',top=' +
      top +
      ',left=' +
      left
    );
  }
}
