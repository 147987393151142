import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

export class FlowHelpers {
  static isObject(predicate: any): boolean {
    return predicate !== null && typeof predicate === 'object';
  }

  static isEmptyObject(predicate: any): boolean {

    if (!this.isObject(predicate)) {
      return true;
    }

    for (const key in predicate) {
      if (Object.prototype.hasOwnProperty.call(predicate, key)) {
        return false;
      }
    }
    return true;
  }

  static isArray(predicate: any): boolean {
    return Array.isArray(predicate);
  }

  static isEmptyArray(predicate: any[]): boolean {
    return !this.isArray(predicate) || !predicate.length;
  }

  static hasProperty(object: any, key: string): boolean {
    return !this.isEmptyObject(object) && Object.prototype.hasOwnProperty.call(object, key);
  }

  static parseToPrimitive(value: string): any {

    if (!value) {
      return false;
    }

    const testValue = this.lowerCase(value);
    if (testValue === 'true') {
      return true;
    }
    else if (testValue === 'false' || testValue === 'null') {
      return false;
    }
    else if (this.isNumberLike(value)) {
      return parseFloat(value);
    }

    return value;
  }

  static isBooleanLike(value: string): boolean {
    const testValue = value.toLowerCase();
    return (testValue === 'true' || testValue === 'false');
  }

  static isString(value: any): boolean {
    return (typeof value === 'string' || value instanceof String);
  }

  static isNumberLike(value: any): boolean {
    return !isNaN(parseFloat(value)) && !isNaN(value - 0);
  }

  /**
   * Creates a globally unique identifier (GUID)
   */
  static createGuid(): string {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }


  /**
   * Creates a timestamp to avoid cached results problems.
   */
  static timestamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  static vendorName(vendorId: string): string {
    const name = this.lowerCase(vendorId);

    if (name === 'flow') {
      return 'Flow';
    }
    else if (name === 'salesdemo') {
      return 'SalesDemo';
    }

    return vendorId;
  }

  /**
   * Flat a Http Error Response.
   */
  static flatHttpErrorResponse(response: HttpErrorResponse, labelPath = 'generic'): string {
    const fn = (item: any, label: string) => {
      if (this.isObject(response.error) && this.hasProperty(item, 'error')) {
        label = `${labelPath}.${item.error}`;
        return fn(item.error, label);
      }
      return label;
    };

    return fn(response.error, 'generic.request.failed');
  }

  static trimHTml(predicate: string): string {
    const div = document.createElement('div');
    div.innerHTML = predicate;

    const text = div.textContent || div.innerText || '';

    return text;
  }

  static lowerCase(predicate: string): string {
    return predicate.toLowerCase();
  }

  /**
   * "Safer" String.toUpperCase()
   */
  static upperCase(predicate: string): string {
    return predicate.toUpperCase();
  }

  /**
   * Removes empty and null values from object and returns new object
   */
  static removeEmptyObjectValues(predicate: any): any {
    if (this.isObject(predicate)) {
      return Object.keys(predicate)
        .filter(key => predicate[key] !== '' && predicate[key] !== null) // Remove empty and null values
        .reduce(
          (newObject, key) =>
            typeof predicate[key] === 'object'
              ? { ...newObject, [key]: this.removeEmptyObjectValues(predicate[key]) } // Recurse.
              : { ...newObject, [key]: predicate[key] }, // Copy value.
          {}
        );
    }
    else {
      return predicate;
    }
  }

  /**
   * Similar to array length but for objects.
   */
  static getObjectLength(predicate: any): number {
    let size = 0;
    for (const key in predicate) {
      if (Object.prototype.hasOwnProperty.call(predicate, key)) {
        size += 1;
      }
    }
    return size;
  }

  /**
   * catch observable error as FALSE
   */
  static catchAsFalse() {
    return of(false);
  }

  /**
   * catch observable error as NULL
   */
  static catchAsNull() {
    return of(null);
  }

  /**
   * Wait for a node to be inserted into the DOM
   */
  static waitForNode(params: {
    node: string;
    nodeBy: 'id' | 'class';
    parent?: HTMLElement;
    subtree?: boolean;
    done?: (HTMLElement) => void;
  } = {
    node: null,
    nodeBy: 'id',
    subtree: false
  }): void {
    // No arrow function so `this` is mapped to the MutationObserver scope.
    new MutationObserver(function() {
      const element = params.nodeBy === 'id' ? document.getElementById(params.node) : document.querySelector(params.node);
      if (element) {
        this.disconnect();
        params.done(element);
      }
    }).observe(params.parent || document.body, {
      subtree: params.subtree,
      childList: true
    });
  }

  /**
   * Takes an input object and creates a deep copy.
   * Switch cases can be extended to handle more js instance types.
   */
  static deepClone(predicate: any = {}): any {
    const fn = (item: any) => {
      if (typeof item !== 'object' || item === null) {
        return item;
      }

      switch (item.constructor) {
        case Object:
          return Object.keys(item).reduce((acc, key) => {
            acc[key] = fn(item[key]);
            return acc;
          }, {});
        case Array:
          return item.map(fn);
        case Date:
          return new Date(item.getTime());
        default:
          // Fallback
          return item;
      }
    };

    return fn(predicate);
  }

  /**
   * Takes an input object and creates a deep copy.
   * Avoid using it with advanced js types like objects containing functions as values.
   */
  static simpleClone(predicate: any = {}): any {
    try {
      return JSON.parse(JSON.stringify(predicate));
    }
    catch (e) {
      return predicate;
    }
  }

  /**
   * Searches if given a script id, it exists in the DOM.
   */
  static hasScriptById(predicate: string): boolean {
    const scripts = document.getElementsByTagName('script');
    let found = false;

    for (const script in scripts) {
      if (scripts[script]) {
        const scriptId = scripts[script].id || null;
        if (scriptId && scriptId === predicate) {
          found = true;
        }
      }
    }

    return found;
  }

  /**
   * Transforms a string into a slugified string.
   */
  static slugify(string: string): string {
    if (!string) {
      return '';
    }

    // Unicode (non-control) characters in the Latin-1 Supplement and Latin
    // Extended-A blocks, transliterated into ASCII characters.
    const charmap = {
      ' ': ' ',
      '¡': '!',
      '¢': 'c',
      '£': 'lb',
      '¥': 'yen',
      '¦': '|',
      '§': 'SS',
      '¨': '"',
      '©': '(c)',
      'ª': 'a',
      '«': '<<',
      '¬': 'not',
      '®': '(R)',
      '°': '^0',
      '±': '+/-',
      '²': '^2',
      '³': '^3',
      '´': '\'',
      'µ': 'u',
      '¶': 'P',
      '·': '.',
      '¸': ',',
      '¹': '^1',
      'º': 'o',
      '»': '>>',
      '¼': ' 1/4 ',
      '½': ' 1/2 ',
      '¾': ' 3/4 ',
      '¿': '?',
      'À': '`A',
      'Á': '\'A',
      'Â': '^A',
      'Ã': '~A',
      'Ä': '"A',
      'Å': 'A',
      'Æ': 'AE',
      'Ç': 'C',
      'È': '`E',
      'É': '\'E',
      'Ê': '^E',
      'Ë': '"E',
      'Ì': '`I',
      'Í': '\'I',
      'Î': '^I',
      'Ï': '"I',
      'Ð': 'D',
      'Ñ': '~N',
      'Ò': '`O',
      'Ó': '\'O',
      'Ô': '^O',
      'Õ': '~O',
      'Ö': '"O',
      '×': 'x',
      'Ø': 'O',
      'Ù': '`U',
      'Ú': '\'U',
      'Û': '^U',
      'Ü': '"U',
      'Ý': '\'Y',
      'Þ': 'Th',
      'ß': 'ss',
      'à': '`a',
      'á': '\'a',
      'â': '^a',
      'ã': '~a',
      'ä': '"a',
      'å': 'a',
      'æ': 'ae',
      'ç': 'c',
      'è': '`e',
      'é': '\'e',
      'ê': '^e',
      'ë': '"e',
      'ì': '`i',
      'í': '\'i',
      'î': '^i',
      'ï': '"i',
      'ð': 'd',
      'ñ': '~n',
      'ò': '`o',
      'ó': '\'o',
      'ô': '^o',
      'õ': '~o',
      'ö': '"o',
      '÷': ':',
      'ø': 'o',
      'ù': '`u',
      'ú': '\'u',
      'û': '^u',
      'ü': '"u',
      'ý': '\'y',
      'þ': 'th',
      'ÿ': '"y',
      'Ā': 'A',
      'ā': 'a',
      'Ă': 'A',
      'ă': 'a',
      'Ą': 'A',
      'ą': 'a',
      'Ć': '\'C',
      'ć': '\'c',
      'Ĉ': '^C',
      'ĉ': '^c',
      'Ċ': 'C',
      'ċ': 'c',
      'Č': 'C',
      'č': 'c',
      'Ď': 'D',
      'ď': 'd',
      'Đ': 'D',
      'đ': 'd',
      'Ē': 'E',
      'ē': 'e',
      'Ĕ': 'E',
      'ĕ': 'e',
      'Ė': 'E',
      'ė': 'e',
      'Ę': 'E',
      'ę': 'e',
      'Ě': 'E',
      'ě': 'e',
      'Ĝ': '^G',
      'ĝ': '^g',
      'Ğ': 'G',
      'ğ': 'g',
      'Ġ': 'G',
      'ġ': 'g',
      'Ģ': 'G',
      'ģ': 'g',
      'Ĥ': '^H',
      'ĥ': '^h',
      'Ħ': 'H',
      'ħ': 'h',
      'Ĩ': '~I',
      'ĩ': '~i',
      'Ī': 'I',
      'ī': 'i',
      'Ĭ': 'I',
      'ĭ': 'i',
      'Į': 'I',
      'į': 'i',
      'İ': 'I',
      'ı': 'i',
      'Ĳ': 'IJ',
      'ĳ': 'ij',
      'Ĵ': '^J',
      'ĵ': '^j',
      'Ķ': 'K',
      'ķ': 'k',
      'Ĺ': 'L',
      'ĺ': 'l',
      'Ļ': 'L',
      'ļ': 'l',
      'Ľ': 'L',
      'ľ': 'l',
      'Ŀ': 'L',
      'ŀ': 'l',
      'Ł': 'L',
      'ł': 'l',
      'Ń': '\'N',
      'ń': '\'n',
      'Ņ': 'N',
      'ņ': 'n',
      'Ň': 'N',
      'ň': 'n',
      'ŉ': '\'n',
      'Ō': 'O',
      'ō': 'o',
      'Ŏ': 'O',
      'ŏ': 'o',
      'Ő': '"O',
      'ő': '"o',
      'Œ': 'OE',
      'œ': 'oe',
      'Ŕ': '\'R',
      'ŕ': '\'r',
      'Ŗ': 'R',
      'ŗ': 'r',
      'Ř': 'R',
      'ř': 'r',
      'Ś': '\'S',
      'ś': '\'s',
      'Ŝ': '^S',
      'ŝ': '^s',
      'Ş': 'S',
      'ş': 's',
      'Š': 'S',
      'š': 's',
      'Ţ': 'T',
      'ţ': 't',
      'Ť': 'T',
      'ť': 't',
      'Ŧ': 'T',
      'ŧ': 't',
      'Ũ': '~U',
      'ũ': '~u',
      'Ū': 'U',
      'ū': 'u',
      'Ŭ': 'U',
      'ŭ': 'u',
      'Ů': 'U',
      'ů': 'u',
      'Ű': '"U',
      'ű': '"u',
      'Ų': 'U',
      'ų': 'u',
      'Ŵ': '^W',
      'ŵ': '^w',
      'Ŷ': '^Y',
      'ŷ': '^y',
      'Ÿ': '"Y',
      'Ź': '\'Z',
      'ź': '\'z',
      'Ż': 'Z',
      'ż': 'z',
      'Ž': 'Z',
      'ž': 'z',
      'ſ': 's'
    };

    const ascii = [];
    let char;
    let code;

    // trim html from string
    const div = document.createElement('div');
    div.innerHTML = string;
    string = div.textContent || div.innerText || string;

    for (let i = 0; i < string.length; i++) {
      if ((code = string.charCodeAt(i)) < 0x180) {
        char = String.fromCharCode(code);
        ascii.push(charmap[char] || char);
      }
    }

    string = ascii.join('');
    string = string.replace(/[^\w\s-]/g, '').trim().toLowerCase();

    return string.replace(/[-\s]+/g, '-');
  }

  /**
   *  Transform string into acronym
   */
  static acronym(name: string): string {
    if (name) {
      // 1. get the first character of every name
      // 2. transform array into string
      // 3. transform string to uppercase string
      return name.match(/\b(\w)/g).join('').toUpperCase();
    }

    return name;
  }
}
