import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

import { FlowUserService } from '@flow/auth';

import { FlowPortalService } from '../../../services/portal/portal.service';

@Component({
  selector: 'flow-portal-logo',
  templateUrl: './portal-logo.component.html',
  styleUrls: ['./portal-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowPortalLogoComponent implements OnInit {
  @Input() backgroundPosition: string[];

  portalLogoUrl: string;

  loadEffect: 'fadeIn' | 'blur' = 'fadeIn';

  constructor(
    private UserService: FlowUserService,
    private PortalService: FlowPortalService
  ) {}

  ngOnInit() {
    const vendors = this.UserService.user
      ? this.UserService.user.vendors.filter(vendor => vendor.toLowerCase() !== 'tiekinetix')
      : [];

    // Set default position if not available
    if (!this.backgroundPosition) {
      this.backgroundPosition = ['left', 'center'];
    }

    // Show FLOW logo if a user has more than 1 vendors
    this.portalLogoUrl =
      vendors.length > 1
        ? 'https://tie-flow-images.storage.googleapis.com/flow/vendor_logos/flow.png?t=1603981553'
        : this.PortalService.logo;
  }
}
