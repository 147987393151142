import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductModelStateInterface } from '@marketplaceStore/reducers/product.reducer';
import { FlowMarketplaceStateInterface } from '@marketplaceStore';
import { FlowProductModelInterface } from '@flow/core';

export const getProductsSelector = createFeatureSelector<FlowMarketplaceStateInterface, ProductModelStateInterface>('products');

export const getProductsLoaded = createSelector(
  getProductsSelector,
  (state: ProductModelStateInterface) => state.loaded
);

export const getFlowProducts = createSelector(
  getProductsSelector,
  (state: ProductModelStateInterface) => state.data
);

export const getFlowActiveProducts = createSelector(
  getFlowProducts,
  (products: FlowProductModelInterface[]) => products.filter(product => product.active)
);

export const getBoughtProducts = createSelector(
  getFlowProducts,
  (products: FlowProductModelInterface[]) => products.filter(product => product.boughtByCurrentUser)
);

export const getBoughtPartnerProducts = createSelector(
  getFlowProducts,
  (products: FlowProductModelInterface[]) => products.filter(product => product.boughtByPartnerUser)
);

export const getProductName = createSelector(
  getFlowProducts,
  (products: FlowProductModelInterface[], id: string) => {
    const product = products.find(items => items.id === id);
    return product ? product.name : id;
  }
);

export const getProductById = createSelector(
  getFlowProducts,
  (products: FlowProductModelInterface[], id: string) => products.find(product => product.id === id)
);

export const getProductsByIds = createSelector(
  getFlowProducts,
  (products: FlowProductModelInterface[], ids: string[]) => products.filter(product => ids.indexOf(product.id) !== -1)
);
