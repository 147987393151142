/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FlowEnvService, FlowHelpers, FlowUtilsService, FlowVendorDashboardInterface } from '@flow/core';
import { FlowTokensService, FlowUserService, FlowProjectService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import {
  FlowMarketplaceDashboardService
} from '../../../marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';

import {
  FlowPortalCustomHelpSiteService
} from '../../../admin/modules/portal/components/portal-custom-help-site/portal-custom-help-site.service';

interface widgetAttributes {
  config: any;
  labels: FlowTranslateLabel;
  layout: any;
  options: any;
  tokens: any;
}

interface customHelpSite {
  inModal: boolean;
  url: string;
}

@Component({
  selector: 'flow-help-icon',
  templateUrl: './help-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowHelpIconComponent implements OnInit {

  @HostBinding('class') classNames = 'help-icon';

  showSupportWidget$: Observable<boolean>;

  widgetAttributes: widgetAttributes;

  private _isPartner: boolean;

  private _showSupportFormForPartners: boolean;

  private _customHelpSite: customHelpSite;

  // Keep in comments in case we need to use it in the future
  // products$: Observable <FlowProductModelInterface[]>;

  constructor(
    // private store: Store <FlowMarketplaceStateInterface>,
    private EnvService: FlowEnvService,
    private UtilsService: FlowUtilsService,
    private ProjectService: FlowProjectService,
    private TokensService: FlowTokensService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private MarketplaceDashboardService: FlowMarketplaceDashboardService,
    private PortalCustomHelpSiteService: FlowPortalCustomHelpSiteService,
  ) { }

  ngOnInit(): void {
    this._isPartner = this.UserService.isActingAsPartner();

    this._customHelpSite = this._checkForCustomHelpSite();

    this.showSupportWidget$ = this._getIfShowSupportWidget();

    // this.products$ = this.store.pipe(select(getFlowProducts));
  }

  private _checkForCustomHelpSite(): customHelpSite {
    const _customHelpSite = {
      inModal: false,
      url: ''
    };

    const dashboard: FlowVendorDashboardInterface = !this._isPartner
      ? this.MarketplaceDashboardService.getVendorDashboard()
      : this.MarketplaceDashboardService.getProjectDashboard();

    const { customHelpSite } = dashboard;

    if (customHelpSite) {
      const { target } = customHelpSite;

      switch (target) {
        case 'website':
          _customHelpSite.url = this.PortalCustomHelpSiteService.getLanguageBasedUrl(dashboard.customHelpSite.url);
          _customHelpSite.inModal = dashboard.customHelpSite.view === 'modal';
          break;

        case 'email':
          _customHelpSite.url = this.PortalCustomHelpSiteService.generateMailto(dashboard.customHelpSite);
          break;

        default: /* backwards-compatibility; aka pre "target", ake pre separation of "website" and "email" */
        _customHelpSite.url = dashboard.customHelpSite.url;
          break;
      }
    }

    return _customHelpSite;
  }

  // Will be deprecated in the future
  private _getEnv(): string {
    switch (true) {
      case this.EnvService.isTest:
        return 'test';

      case this.EnvService.isPreAccept:
        return 'preaccept';

      case this.EnvService.isAcceptance:
        return 'acceptance';

      default:
        return 'production';
    }
  }

  private _getIfShowSupportWidget(): Observable<boolean> {
    // Vendor users will see the support widget icon in any case
    if (!this._isPartner) {
      return of(true)
        .pipe(
          tap(() => { this.widgetAttributes = this._setWidgetAttributes() })
        );
    }
    // Partner users will see the support widget icon based on some conditions
    else {
      return this.ProjectService.getProjectExtras()
        .pipe(
          map(response => {
            // Keep this for backwards compatibility
            const showZendeskForPartners = this.UtilsService.getExtra(response, 'showZendeskForPartners', 'fieldName');
            // New generic extra to show support form for partners
            const showSupportFormForPartners = this.UtilsService.getExtra(response, 'showSupportFormForPartners', 'fieldName');

            if (
              ( showZendeskForPartners && FlowHelpers.parseToPrimitive(showZendeskForPartners.value) )
              ||
              ( showSupportFormForPartners && FlowHelpers.parseToPrimitive(showSupportFormForPartners.value) )
            ) {
              this._showSupportFormForPartners = true;
              this.widgetAttributes = this._setWidgetAttributes();

              return true; // Show help icon for partners and trigger support form
            }
            else {
              if (!!this._customHelpSite.url) {
                this._showSupportFormForPartners = false;
                this.widgetAttributes = this._setWidgetAttributes();

                return true; // Show help icon for partners and trigger custom help site
              }
              else {
                return false; // Hide help icon for partners
              }
            }
          })
        );
    }
  }

  private _getWidgetConfig(): any {
    const allowedFileExtensions = [
      '.doc',
      '.docx',
      '.pdf',
      '.jpeg',
      '.jpg',
      '.png',
      '.gif',
      '.txt',
      '.csv',
      '.xls',
      '.xlsx'
    ];

    const allowedMimeTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'image/jpeg',
      'image/png',
      'image/gif',
      'text/plain',
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    return {
      contactEmail: this.UserService.username,
      contactName: this.UserService.fullName,
      env: this._getEnv(), // TODO: Improve later and add new method to get the environment in @flow/core,
      maxFiles: 5,
      maxUploadSize: 25 * 1024 * 1024, // 25 MB
      allowedFileExtensions,
      allowedMimeTypes
    };
  }

  private _getWidgetLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'supportform.btn.help',
      'supportform.title',
      'supportform.aria-label.close-form',
      'supportform.subtitle',
      'supportform.label.contact-name',
      'supportform.label.contact-email',
      'supportform.label.additional-emails',
      'supportform.hint.additional-emails',
      'supportform.error.additional-emails',
      'supportform.label.experiencing-issue',
      'supportform.placeholder.experiencing-issue',
      'supportform.hint.experiencing-issue',
      'supportform.label.tradingpartners',
      'supportform.hint.tradingpartners',
      'supportform.label.percentage',
      'supportform.label.percentage_lessThan25',
      'supportform.label.percentage_between25and50',
      'supportform.label.percentage_between50and75',
      'supportform.label.percentage_moreThan75',
      'supportform.label.percentage_notApplicable',
      'supportform.label.daily-business',
      'supportform.label.daily-business_no',
      'supportform.label.daily-business_workaround',
      'supportform.label.daily-business_yes',
      'supportform.label.daily-business_cannot_conduct_business',
      'supportform.label.daily-business_notApplicable',
      'supportform.label.critical-business',
      'supportform.label.critical-business_immediately',
      'supportform.label.critical-business_within24hrs',
      'supportform.label.critical-business_within48hrs',
      'supportform.label.critical-business_moreThan48hrs',
      'supportform.label.critical-business_noImpact',
      'supportform.aria-label.select-option',
      'supportform.title.upload-files',
      'supportform.description.upload-files',
      'supportform.label.maximum',
      'supportform.label.format-accepted',
      'supportform.aria-label.remove-file',
      'supportform.btn.close',
      'supportform.btn.cancel',
      'supportform.btn.submit',
      'supportform.title.success',
      'supportform.subtitle.success',
      'supportform.aria-label.success',
      'supportform.title.failure',
      'supportform.btn.try.again',
      'supportform.aria-label.failure',
      'supportform.error.field-required',
      'supportform.error.failed-upload',
      'supportform.error.failed-uploads',
      'supportform.label.file-preview.type_word',
      'supportform.label.file-preview.type_image',
      'supportform.label.file-preview.type_text',
      'supportform.label.file-preview.type_spredsheet',
      {
        key: 'supportform.error.max-file-size-exceeded',
        params: {
          maxFileSize: '{{maxFileSize}}'  // Workaround to interpolate the value later once available
        }
      },
      {
        key: 'supportform.error.max-files-exceeded',
        params: {
          maxFiles: '{{maxFiles}}'  // Workaround to interpolate the value later once available
        }
      }
    ]);
  }

  private _getWidgetLayout(): any {
    return {
      materialIconId: 'help',
      position: {
        xOffset: -195,
        yOffset: -25
      },
      triggerType: 'iconButton',
    };
  }

  private _getWidgetOptions(): any {
    return {
      customHelpSite: this._customHelpSite,
      isPartner: this._isPartner,
      showSupportFormForPartners: this._showSupportFormForPartners
    };
  }

  private _getWidgetTokens(): any {
    const tokens = this.TokensService.getTokens();

    return {
      strategy: this.TokensService.tokenStrategy,
      refreshToken: tokens.refreshToken,
      token: tokens.token
    };
  }

  private _setWidgetAttributes(): widgetAttributes {
    return {
      config: this._getWidgetConfig(),
      labels: this._getWidgetLabels(),
      layout: this._getWidgetLayout(),
      options: this._getWidgetOptions(),
      tokens: this._getWidgetTokens()
    };
  }
}
