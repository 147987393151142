import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlowEnvService } from '@flow/core';
import { FlowLayoutService } from '@flow/shared';

import { FlowSupportFormService } from '../../services/support-form.service';

import { FlowSupportFormComponent } from '../support-form/support-form.component';

import {
  FlowSupportWidgetConfig,
  FlowSupportWidgetLabels,
  FlowSupportWidgetLayout,
  FlowSupportWidgetOptions,
  FlowSupportWidgetTokens
 } from '../../interfaces';


@Component({
  selector: 'flow-support-widget',
  templateUrl: './support-widget.component.html',
  styleUrls: ['./support-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowSupportWidgetComponent implements OnInit, OnDestroy {

  @Input() config: FlowSupportWidgetConfig;

  @Input() labels: FlowSupportWidgetLabels;

  @Input() layout: FlowSupportWidgetLayout;

  @Input() options: FlowSupportWidgetOptions;

  @Input() tokens: FlowSupportWidgetTokens;

  @ViewChild('iconButton', { read: ElementRef }) iconButton: ElementRef;

  isMobile: boolean;
  isStandardEinvoicingProject: boolean;

  private dialogRef: MatDialogRef<FlowSupportFormComponent>;

  private _hasCustomHelpSite: boolean;

  private _destroy$$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _matDialog: MatDialog,
    private EnvService: FlowEnvService,
    private LayoutService: FlowLayoutService,
    private SupportFormService: FlowSupportFormService
  ) {}

  ngOnInit(): void {
     // Check device
    this.LayoutService.isMobile$
      .pipe(
        takeUntil(this._destroy$$)
      )
      .subscribe(isMobile => {
        this.isMobile = isMobile;
        this.changeDetectorRef.markForCheck();
      });

    this.isStandardEinvoicingProject = this.EnvService.isStandardEinvoicingProject;

    this._hasCustomHelpSite = this.SupportFormService.getIfHasCustomHelpSite(this.options);
  }

  ngOnDestroy(): void {
    this._destroy$$.next();
    this._destroy$$.complete();
  }

  /* @HostListener('window:resize')
  onResize(): void {
    this._setDialogPosition();
  } */

  openSupportForm(): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        config: this.config,
        labels: this.labels,
        options: this.options,
        tokens: this.tokens
      },
      panelClass: 'dialog-wrapper',
      disableClose: true,
      autoFocus: false
    };

    this.dialogRef = this._matDialog.open(FlowSupportFormComponent, dialogConfig);

    // this._setDialogPosition();

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  triggerHelp(): void {
    // User is not a partner
    // -> open support form
    if (!this.options.isPartner) {
      this.openSupportForm();
    }
    // User is a partner
    // -> open support form, if showSupportFormForPartners is set
    // -> open custom help site, if customHelpSite is set
    else {
      this.options.showSupportFormForPartners
        ? this.openSupportForm()
        : this._hasCustomHelpSite && this.SupportFormService.openCustomHelpSite(this.options.customHelpSite, this.labels);
    }
  }

  /* private _setDialogPosition(): void {
    if (this.dialogRef) {
      const iconRect = this.iconButton.nativeElement.getBoundingClientRect();

      this.dialogRef.updatePosition({
        top: `${iconRect.bottom + this.layout.position.yOffset}px`,
        left: `${iconRect.left + this.layout.position.xOffset}px`
      });
    }
  } */
}
