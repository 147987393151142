<p
  class="text-center mt-lg-5 pt-lg-5 pb-lg-4"
  [ngClass]="{ 'color-danger': authFailed }"
>
  Authorizing
  @if (loading) {
    <span>...</span>
  }
  @if (authFailed) {
    <span> failed!</span>
  }
</p>
<flow-preloader [hidden]="!loading" />
