@if (isStandardEinvoicingProject) {
  @if (bookmarkType === 'dashboard' || bookmarkType === 'more') {
    <i class="material-icons mobile-portrait-icon-only" [innerHTML]="icon"></i>
    <span class="ml-0 mobile-portrait-icon-only" [innerHTML]="label"></span>
  } @else {
    <span class="ml-0" [innerHTML]="label"></span>
  }
} @else {
  @switch (iconClass) {
    @case ('material-icons') {
      <i class="material-icons" [innerHTML]="icon"></i>
    }
    @case ('flow-icon') {
      @if (!hideIcon) {
        <i class="flow-icon" [ngClass]="'icon-' + icon"></i>
      }
    }
  }

  @if (label) {
    <span [innerHTML]="label" [ngClass]="{ 'ml-0': hideIcon }"></span>
  }
}
