<button
  *ngIf="layout.triggerType === 'iconButton'"
  #iconButton
  class="button"
  (click)="triggerHelp()">
  <div>
    <i
      class="material-icons"
      [matTooltip]="isMobile && isStandardEinvoicingProject
        ? undefined
        : labels['supportform.btn.help']"
      [attr.aria-label]="labels['supportform.btn.help']"
    >
      {{ layout.materialIconId }}
    </i>
    @if (isMobile && isStandardEinvoicingProject) {
      <div class="text-uppercase">
        {{ labels['supportform.btn.help'] }}
      </div>
    }
  </div>
</button>
