<button
  *ngIf="layout.triggerType === 'iconButton'"
  #iconButton
  class="button"
  (click)="triggerHelp()">
  <i
    class="material-icons"
    [matTooltip]="labels['supportform.btn.help']"
    [attr.aria-label]="labels['supportform.btn.help']">
    {{ layout.materialIconId }}
  </i>
</button>
