/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, startWith, switchMap, map, catchError } from 'rxjs/operators';

import { FlowHelpers, FlowUtilsService, FlowWidgetModelInterface } from '@flow/core';
import { FlowAuthService, FlowUserService } from '@flow/auth';
import { getAllWidgets, FlowMarketplaceStateInterface } from '@marketplaceStore';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { FlowMarketplaceDashboardService } from '../../../marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';
import { fadeInOutAnimation } from '../../../../animations';

@Component({
  selector: 'flow-widget-selection',
  templateUrl: './widget-selection.component.html',
  styleUrls: ['./widget-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation]
})
export class FlowWidgetSelectionComponent implements OnInit, OnDestroy {

  //  @ViewChild(MatTabGroup, { static: false }) tabs: MatTabGroup;

  labels: FlowTranslateLabel;

  filteredWidgets$: Observable<FlowWidgetModelInterface[]>;
  visibleWidgets$: Observable<FlowWidgetModelInterface[]>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  visibleWidgetsSubscription$$: Subscription = new Subscription();

  visibleWidgets: FlowWidgetModelInterface[];

  sections: any[];
  selectedIndex = 0;

  searchForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store <FlowMarketplaceStateInterface>,
    private formBuilder: UntypedFormBuilder,
    private UtilsService: FlowUtilsService,
    private AuthService: FlowAuthService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private MarketplaceDashboardService: FlowMarketplaceDashboardService,
    private dialogRef: MatDialogRef <FlowWidgetSelectionComponent>
  ) {
    this.searchForm = this.formBuilder.group({
      search: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    this.labels = this._translateLabels();

    // Filter widgets to be not shown in result list
    this.filteredWidgets$ = combineLatest({
      all: this.store.pipe(select(getAllWidgets)),
      assigned: this.data.widgets
    })
    .pipe(
      map(({ all, assigned }) => this._filterWidgets(all, assigned)),
      switchMap(assigned => this._translateWidgets(assigned))
    );

    this.visibleWidgets$ = combineLatest([
      this.filteredWidgets$,
      this.searchForm.get('search').valueChanges.pipe(
        startWith(''),
        debounceTime(300)
      )
    ])
    .pipe(
      switchMap(([widgets, search]) => {
        if (search) {
          return of(widgets.filter(item => item.name.toLowerCase().search(search.trim().toLowerCase()) > -1));
        }
        return of(widgets);
      })
    );

    this.visibleWidgetsSubscription$$ = this.visibleWidgets$.subscribe(
      widgets => {
        this.visibleWidgets = widgets.map(widget => {
          widget.name = FlowHelpers.trimHTml(widget.name, ' ');
          return widget;
        });

        this.loading$.next(false);
    });

    // this.sections = [
    //   {
    //     id: 0,
    //     label: this.labels['generic.label.all'],
    //     slug: 'all',
    //   },
    //   {
    //     id: 1,
    //     label: this.labels['dashboard.widget_type.iframe'],
    //     slug: 'iframe'
    //   },
    //   {
    //     id: 2,
    //     label: this.labels['dashboard.widget_type.chart'],
    //     slug: 'chart'
    //   },
    //   {
    //     id: 3,
    //     label: this.labels['dashboard.widget_type.link'],
    //     slug: 'link'
    //   },
    //   {
    //     id: 4,
    //     label: this.labels['dashboard.widget_type.list'],
    //     slug: 'list'
    //   },
    //   {
    //     id: 5,
    //     label: this.labels['dashboard.widget_type.logo'],
    //     slug: 'logo'
    //   },
    //   {
    //     id: 6,
    //     label: this.labels['dashboard.widget_type.total'],
    //     slug: 'total'
    //   },
    //   {
    //     id: 7,
    //     label: this.labels['dashboard.widget_type.video'],
    //     slug: 'youtube video'
    //   }
    // ];
  }

  /* ngAfterViewInit(): void {
    // this.visibleWidgets$ = combineLatest([
    //   this.store.pipe(select(getAllWidgets)),
    //   this.tabs.selectedTabChange.pipe(startWith({ index: 0 })),
    //   this.searchForm.get('search').valueChanges.pipe(startWith(''), debounceTime(300))
    // ]).pipe(
    //   switchMap(([widgets, tab, search]) => {
    //     const selectedType = this.sections.find(section => section.id === tab.index);
    //     if (selectedType.slug === 'all') {
    //       if (search) {
    //         return of(widgets.filter(item => item.name.indexOf(search) > -1 ));
    //       }
    //       return of(widgets);
    //     } else {
    //       if (search) {
    //         return of(widgets.filter(item => (item.type === selectedType.slug) && item.name.indexOf(search) > -1 ));
    //       }
    //       return of(widgets.filter(item => (item.type === selectedType.slug)));
    //     }
    //   })
    // );
  } */

  ngOnDestroy(): void {
    this.visibleWidgetsSubscription$$.unsubscribe();
  }

  addWidget(widget: FlowWidgetModelInterface): void {
    this.dialogRef.close(widget);
  }

  /**
   * Filter widgets to be not shown in result list
   */
  private _filterWidgets(all: FlowWidgetModelInterface[], assigned: any): FlowWidgetModelInterface[] {
      return all
      // Filter already assigned widgets
      .filter(widget => assigned.findIndex(assignedWidget => assignedWidget.id === widget._id) === -1)

      // ST040983:
      // Filter allPartnersWidget widgets as they are shown automatically to partners of all projects.
      // For now this option is only set for widgets of type 'omm' (Onboarding Module Management).
      .filter(widget => {
        const allPartnersWidget = this.UtilsService.getExtra(widget.extra, 'allPartnersWidget', 'fieldName');

        if (allPartnersWidget) {
          const { value } = allPartnersWidget;

          return FlowHelpers.parseToPrimitive(value) !== true;
        }

        return true;
      });
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'dashboard.sidebar.label.search',
      'generic.btn.close',
      'dashboard.label.add_widgets',
      'generic.btn.add',
      'generic.label.all',
      'dashboard.widget_type.iframe',
      'dashboard.widget_type.chart',
      'dashboard.widget_type.link',
      'dashboard.widget_type.list',
      'dashboard.widget_type.logo',
      'dashboard.widget_type.total',
      'dashboard.widget_type.video',
    ]);
  }

  /**
   * Get translations for assigned widgets
   */
  private _translateWidgets(assigned: FlowWidgetModelInterface[]): Observable<FlowWidgetModelInterface[]> {
    const vendor = this.AuthService.vendor;

    // Fetch textblocks for flow and customer widgets
    return this.MarketplaceDashboardService.getWidgetTranslations()
    .pipe(
      map(result => {
        // No textblocks found -> return assigned widgets untouched
        if (result[0].total === 0 && result[1].total === 0) {
          return assigned;
        }
        // Textblocks found -> modify name and description property of widgets with found translations
        else {
          const clonedAssigned = FlowHelpers.deepClone(assigned);
          const currentLang = this.UserService.language;

          result.forEach(textblocks => {
            textblocks.data.forEach(textblock => {
              const translation = textblock.translations[currentLang];

              if (translation && translation !== '') {
                const idParts = textblock.id.split('.');
                const foundWidgetIndex = clonedAssigned.findIndex(widget => widget._id === idParts[2]);

                if (foundWidgetIndex > -1) {
                  clonedAssigned[foundWidgetIndex][idParts[3]] = translation;
                }
              }
            });
          });

          return clonedAssigned;
        }
      }),
      catchError(() => of(assigned))
    );
  }
}
