/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FlowComponentsModule, FlowDirectivesModule, FlowMaterialModule, FlowPipesModule } from '@flow/shared';
import { FlowTranslateModule } from '@flow/translate';

import { FlowMarketplaceSharedModule } from '../marketplace-shared/marketplace-shared.module';
import { FlowChatModule } from '../chat/chat.module';

import { FlowHeaderComponent } from './containers/header/header.component';
import { FlowContentComponent } from './containers/content/content.component';
import { FlowFooterComponent } from './containers/footer/footer.component';

import { FlowMenuMainComponent } from './containers/menu-main/menu-main.component';
import { FlowMenuTopComponent } from './containers/menu-top/menu-top.component';
import { FlowOnboardingWrapperComponent } from './containers/onboarding-wrapper/onboarding-wrapper.component';

import { FlowIframeDirective } from './directives/iframe/iframe.directive';

import { FlowMenuMainBookmarksComponent } from './components/menu-main-bookmarks/menu-main-bookmarks.component';
import { FlowMenuMainBookmarkComponent } from './components/menu-main-bookmark/menu-main-bookmark.component';
import { FlowWrapperComponent } from './containers/wrapper/wrapper.component';
import { FlowMenuTopNotificationsComponent } from './components/menu-top-notifications/menu-top-notifications.component';
import { FlowMenuTopNotificationComponent } from './components/menu-top-notification/menu-top-notification.component';

import { FlowHelpComponent } from './containers/help/help.component';
import { FlowHelpIconComponent } from './containers/help-icon/help-icon.component';
import { FlowHelpBlockComponent } from './components/help-block/help-block.component';
import { FlowHelpMenuComponent } from './components/help-menu/help-menu.component';
import { FlowHelpSectionLoaderDirective } from './directives/help-section-loader/help-section-loader.directive';
import { FlowContactFormComponent } from './components/contact-form/contact-form.component';
import { FlowHelpVirtualToursComponent } from './components/help-virtual-tours/help-virtual-tours.component';
import { FlowHelpZendeskComponent } from './components/help-zendesk/help-zendesk.component';
import { FlowHelpCustomSiteComponent } from './components/help-custom/help-custom.component';

import { FlowSupportWidgetComponent } from './components/salesforce-support-widget/components/support-widget/support-widget.component';
import { FlowSupportFormComponent } from './components/salesforce-support-widget/components/support-form/support-form.component';
import { FlowFileUploadComponent } from './components/salesforce-support-widget/components/file-upload/file-upload.component';
import {
  FlowCustomHelpSiteDialogComponent
} from './components/salesforce-support-widget/components/custom-help-site-dialog/custom-help-site-dialog.component';

import { FlowTruncateFileNamePipe } from './components/salesforce-support-widget/pipes/truncate-file-name.pipe';
import { FlowTruncateFileExtensionPipe } from './components/salesforce-support-widget/pipes/truncate-file-extension.pipe';
import { FlowFileIconPipe } from './components/salesforce-support-widget/pipes/file-icon.pipe';
import { FlowFileTypePipe } from './components/salesforce-support-widget/pipes/file-type.pipe';

import { FlowModalTermsAgreementComponent } from './modals/modal-terms-agreement/modal-terms-agreement.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FlowTranslateModule,
    FlowMaterialModule,
    FlowPipesModule,
    FlowDirectivesModule,
    FlowComponentsModule,
    FlowChatModule,
    FlowMarketplaceSharedModule
  ],
  declarations: [
    FlowModalTermsAgreementComponent,
    FlowWrapperComponent,
    FlowHeaderComponent,
    FlowContentComponent,
    FlowFooterComponent,
    FlowMenuMainComponent,
    FlowMenuTopComponent,
    FlowIframeDirective,
    FlowMenuMainBookmarksComponent,
    FlowMenuMainBookmarkComponent,
    FlowMenuTopNotificationsComponent,
    FlowMenuTopNotificationComponent,
    FlowOnboardingWrapperComponent,
    FlowHelpComponent,
    FlowHelpIconComponent,
    FlowHelpBlockComponent,
    FlowHelpMenuComponent,
    FlowHelpSectionLoaderDirective,
    FlowContactFormComponent,
    FlowHelpVirtualToursComponent,
    FlowHelpZendeskComponent,
    FlowHelpCustomSiteComponent,
    FlowSupportWidgetComponent,
    FlowSupportFormComponent,
    FlowFileUploadComponent,
    FlowCustomHelpSiteDialogComponent,
    FlowTruncateFileNamePipe,
    FlowTruncateFileExtensionPipe,
    FlowFileIconPipe,
    FlowFileTypePipe
  ],
  exports: [
    FlowHeaderComponent,
    FlowContentComponent,
    FlowFooterComponent,
    FlowMenuMainComponent,
    FlowMenuTopComponent,
    FlowIframeDirective
  ],
  providers: [
    FlowTruncateFileNamePipe
  ]
})
export class FlowLayoutModule {
}
