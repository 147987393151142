import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

import { FlowModelsService, FlowRouterService } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-modal-delete-confirm',
  templateUrl: 'modal-delete-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowDeleteConfirmComponent implements OnInit, OnDestroy {
  dialogData: any;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  deleting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  errorMsg$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FlowDeleteConfirmComponent>,
    private UserService: FlowUserService,
    private ModelsService: FlowModelsService,
    private TranslateService: FlowTranslateService,
    private RouterService: FlowRouterService
  ) {
    const defaults: any = {
      title: null,
      content: null,
      okButtonLabel: 'general.label.yes',
      cancelButtonLabel: 'general.label.no',
      cancelOnErrorButtonLabel: 'general.btn.cancel',
    };

    this.dialogData = Object.assign(defaults, this.data);
    this.loading$.next(true);
  }

  ngOnInit(): void {
    const toLoad = [
      this.dialogData.okButtonLabel,
      this.dialogData.cancelButtonLabel,
      this.dialogData.cancelOnErrorButtonLabel
    ];

    if (this.dialogData.title) {
      toLoad.push(this.dialogData.title);
    }

    if (this.dialogData.content) {
      toLoad.push(this.dialogData.content);
    }

    if (this.dialogData.translatedContent) {
      this.dialogData.content = this.dialogData.translatedContent;
    }

    this.TranslateService.fetchLabels(toLoad).subscribe(
      (data) => {
        if (this.dialogData.title && data[this.dialogData.title]) {
          this.dialogData.title = data[this.dialogData.title];
        }

        if (this.dialogData.content && data[this.dialogData.content]) {
          this.dialogData.content = data[this.dialogData.content];
        }

        if (data[this.dialogData.okButtonLabel]) {
          this.dialogData.okButtonLabel = data[this.dialogData.okButtonLabel];
        }

        if (data[this.dialogData.cancelButtonLabel]) {
          this.dialogData.cancelButtonLabel =
            data[this.dialogData.cancelButtonLabel];
        }

        if (data[this.dialogData.cancelOnErrorButtonLabel]) {
          this.dialogData.cancelOnErrorButtonLabel =
            data[this.dialogData.cancelOnErrorButtonLabel];
        }

        this.loading$.next(false);
      }
    );
  }

  ngOnDestroy(): void {
    this.loading$.complete();
    this.deleting$.complete();
  }

  cancel() {
    this.dialogRef.close(false);
  }

  deleteEntity() {
    const platform = this.UserService.company;
    const routeOnSuccess = this.dialogData['routeOnSuccess'];

    // Revert error message if any
    this.errorMsg$.next(null);

    // Show process indicator
    this.deleting$.next(true);

    if (this.dialogData['model'] && this.dialogData['entityId']) {
      this.ModelsService.delete(
        this.dialogData['model'],
        this.dialogData['entityId'],
        { platform }
      ).subscribe(
        () => {
          this.dialogRef.close(true);
          routeOnSuccess && this.RouterService.navigate([routeOnSuccess]);
        },
        (error) => {
          this._getErrorMessage(error);
        }
      );
    } else if (this.dialogData['method']) {
      this.dialogData['method'].subscribe(
        () => {
          this.dialogRef.close(true);
          routeOnSuccess && this.RouterService.navigate([routeOnSuccess]);
        },
        (error) => {
          this._getErrorMessage(error);
        }
      );
    } else {
      this.dialogRef.close(false);
      throw new ReferenceError(`Missing properties to delete this entity.`);
    }
  }

  private _getErrorMessage(error: any) {
    let errorMessage: string;
    let isTranslatable: boolean;

    // Modal title
    this.dialogData['error.modal.error.title'] = this.TranslateService.instant({
      key: 'error.modal.error.title',
    });

    // Modal message
    if (error instanceof HttpErrorResponse) {
      if (error.status === 403) {
        const errorResponseMsg = error.error.error.toLowerCase();

        if (errorResponseMsg) {
          const foundTranslationId = errorResponseMsg.match(/{{(.*?)}}/);

          if (foundTranslationId) {
            errorMessage = foundTranslationId[1];
            isTranslatable = true;
          } else {
            errorMessage = errorResponseMsg;
            isTranslatable = false;
          }
        }
      }
    }

    if (errorMessage) {
      this.errorMsg$.next(
        isTranslatable
          ? this.TranslateService.instant({ key: errorMessage })
          : errorMessage
      );
    } else {
      this.errorMsg$.next(
        this.TranslateService.instant({ key: 'error.general.error_loading' })
      );
    }

    // Hide process indicator
    this.deleting$.next(false);
  }
}
