import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { FlowMasterGuard } from '@flow/core';
import { FlowUserGuard } from '@flow/auth';
import { FlowLabelsLoaderGuard } from '@flow/translate';

import { FlowProjectTypeGuard } from './guards/project-type.guard';
import { FlowProjectDomainGuard } from './guards/project-domain.guard';
import { FlowGatewayGuard } from './guards/gateway.guard';
import { FlowOnboardingGuard } from './guards/onboarding.guard';
import { FlowPasswordExpireGuard } from './guards/password-expire.guard';
import { FlowPasswordExpiredGuard } from './guards/password-expired.guard';
import { FlowEnforced2FaGuard } from './guards/enforced-2fa.guard';
import { FlowMissingInfoGuard } from './guards/missing-info.guard';
import { FlowMissingInfoGuard as FlowMissingInfoRouteGuard } from './modules/missing-info/guards/missing-info.guard';
import { FlowProductsLoaderGuard } from './guards/products.guard';
import { FlowCategoriesLoaderGuard } from './guards/categories.guard';
import { FlowHasNavigationExtrasStateGuard } from './guards/has-navigation-extras-state.guard';
import { FlowHomepageGuard } from './guards/homepage.guard';
import { FlowApplicationReadyGuard } from './guards/app-ready.guard';
import { FlowDashboardsGuard } from './guards/dashboards.guard';
import { FlowWidgetsLoaderGuard } from './guards/widgets.guard';
import { FlowServerErrorMessageGuard } from './guards/server-error-message.guard';

// Resolvers
import { FlowDmcAssetsLoaderResolver } from './resolvers/dmc-assets-loader.resolver';

// Components
import { FlowProjectAccessComponent } from './modules/project-access/containers/project-access/project-access.component';
import { FlowGatewayComponent } from './modules/gateway/containers/gateway/gateway.component';
import { FlowPasswordExpiredComponent } from './modules/password-expired/containers/password-expired/password-expired.component';
import { FlowEnforced2FaComponent } from './modules/enforced-2fa/containers/enforced-2fa/enforced-2fa.component';
import { FlowMissingInfoComponent } from './modules/missing-info/containers/missing-info/missing-info.component';
import { FlowWrapperComponent } from './modules/layout/containers/wrapper/wrapper.component';
import { FlowOnboardingWrapperComponent } from './modules/layout/containers/onboarding-wrapper/onboarding-wrapper.component';
import { FlowSupportComponent } from './modules/support/containers/support.component';
import { FlowDisclaimerComponent } from './modules/disclaimer/containers/disclaimer.component';

const routes: Routes = [
  {
    path: '',
    component: FlowWrapperComponent,
    canActivate: [FlowMasterGuard],
    data: {
      guards: [
        FlowServerErrorMessageGuard,
        FlowUserGuard,
        FlowLabelsLoaderGuard,
        FlowProjectTypeGuard,
        FlowProjectDomainGuard,
        FlowOnboardingGuard,
        FlowPasswordExpireGuard,
        FlowEnforced2FaGuard,
        FlowMissingInfoGuard,
        FlowProductsLoaderGuard,
        FlowCategoriesLoaderGuard,
        FlowWidgetsLoaderGuard,
        FlowDashboardsGuard,
        FlowApplicationReadyGuard,
      ],
      animation: 'AppState',
      guardsRelation: 'AND',
      labelsPaths: [
        'account_settings',
        'login',
        'error',
        'general',
        'generic',
        'media',
        'help',
        'msg',
        'flow',
        'chat',
        'email',
        'supportform'
      ]
    },
    resolve: {
      dmcAssetsLoaded: FlowDmcAssetsLoaderResolver,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.FlowDashboardModule)
      },
      {
        path: 'solutions',
        loadChildren: () => import('./modules/solutions/solutions.module').then(m => m.FlowSolutionsModule)
      },
      {
        path: 'administration',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.FlowAdminModule)
      },

      // Adding empty children property as a workaround to trigger the guard to redirect to the homepage.
      // Used this approach to not abuse a component for redirection.
      // redirectTo: 'dashboard' together with canActivate doesn't trigger the guard.
      {
        path: '',
        children: [],
        canActivate: [FlowHomepageGuard],
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'gateway',
    component: FlowGatewayComponent,
    canActivate: [FlowGatewayGuard],
    data: {
      guards: [
        FlowLabelsLoaderGuard
      ],
      labelsPaths: [
        'login'
      ]
    }
  },
  {
    path: 'access',
    component: FlowProjectAccessComponent,
    canActivate: [FlowHasNavigationExtrasStateGuard],
    data: {
      guards: [
        FlowLabelsLoaderGuard
      ],
      labelsPaths: [
        'general', 'login', 'error'
      ],
      extrasStateKey: 'projectAccess'
    }
  },
  {
    path: 'onboarding',
    component: FlowOnboardingWrapperComponent,
    canActivate: [FlowLabelsLoaderGuard],
    data: {
      labelsPaths: [
        'general'
      ]
    },
    children: [
      {
        path: ':project',
        loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.FlowOnboardingModule)
      }
    ]
  },
  {
    path: 'password-expired',
    component: FlowPasswordExpiredComponent,
    canActivate: [FlowPasswordExpiredGuard],
    data: {
      guards: [
        FlowLabelsLoaderGuard
      ],
      labelsPaths: [
        'login', 'general', 'generic', 'account_settings', 'msg'
      ]
    }
  },
  {
    path: 'missing-info',
    component: FlowMissingInfoComponent,
    canActivate: [FlowMissingInfoRouteGuard],
    data: {
      guards: [
        FlowLabelsLoaderGuard
      ],
      labelsPaths: [
        'general', 'generic', 'account_settings', 'msg'
      ]
    }
  },
  {
    path: 'authenticate',
    component: FlowEnforced2FaComponent,
    canActivate: [FlowHasNavigationExtrasStateGuard],
    data: {
      guards: [
        FlowLabelsLoaderGuard
      ],
      labelsPaths: [
        'general', 'login', 'error'
      ],
      extrasStateKey: 'allowed2Fa'
    }
  },
  {
    path: 'support',
    component: FlowSupportComponent,
    canActivate: [FlowLabelsLoaderGuard],
    data: {
      labelsPaths: [
        'general', 'support'
      ]
    }
  },
  {
    path: 'disclaimer',
    component: FlowDisclaimerComponent,
    canActivate: [FlowLabelsLoaderGuard],
    data: {
      labelsPaths: [
        'general'
      ]
    }
  },
  {
    path: 'onboarding-partners',
    component: FlowOnboardingWrapperComponent,
    children: [
      {
        path: ':project',
        loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.FlowOnboardingModule)
      },
    ]
  },

  /* wildcard route to catch all non supported paths */
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'top'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
