import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
} from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { FlowEnvService, FlowUrlViewInterface } from '@flow/core';
import { FlowAuthService, FlowSsoService } from '@flow/auth';
import { FlowPortalService, FlowDialogsService } from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowFooterComponent implements OnInit {
  @Input() showLinks = true;

  spsCompanyName = 'SPS Commerce';

  isStandardEinvoicingProject: boolean;
  isSupplierPortal$: Observable<boolean | any>;

  labels: FlowTranslateLabel;

  currentYear: number;

  logoClasses: string;

  privacySettings: FlowUrlViewInterface;

  constructor(
    private EnvService: FlowEnvService,
    private AuthService: FlowAuthService,
    private SsoService: FlowSsoService,
    private PortalService: FlowPortalService,
    private DialogsService: FlowDialogsService,
    private TranslateService: FlowTranslateService
  ) {
    this.isSupplierPortal$ =
      this.SsoService.getOnBoardingFormForPartners().pipe(
        map((data) => data?.isSupplierPortal), // pluck is marked as deprecated and will be removed in rxjs v8
        shareReplay(1)
      );
  }

  ngOnInit(): void {
    this.isStandardEinvoicingProject = this.EnvService.isStandardEinvoicingProject;

    this.labels = this._translateLabels();

    this.privacySettings = this.PortalService.getCustomerPrivacySettings();

    this.currentYear = new Date().getFullYear();

    this.logoClasses = 'hide_company hide_vertical_line hide_logo_brand';
  }

  openModal(
    title = 'general.privacy_policy.headline',
    content = 'general.privacy_policy.content'
  ): void {
    this.DialogsService.textModal({
      title,
      content,
      showOkButton: false,
    });
  }

  openCustomerPrivacyModal(url: string): void {
    if (url) {
      this.DialogsService.iframeModal({ url });
    }
  }

  openPrivacyModal(): void {
    if (this.AuthService.isDefaultVendor(this.AuthService.vendor)) {
      this.openModal();
    } else {
      this.DialogsService.textModalPrivacy();
    }
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.footer.link.disclaimer',
      'general.footer.link.status_page',
    ]);
  }
}
