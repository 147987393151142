<form
  id="supportForm"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  novalidate>
  <div class="header" mat-dialog-title>
    <h1>{{ data.labels['supportform.title'] }}</h1>
    <div
      (click)="onClose()"
      class="btn-close"
      >
      <i
        class="material-icons"
        [attr.aria-label]="data.labels['supportform.aria-label.close-form']">
        close
      </i>
    </div>
  </div>

  <mat-dialog-content
    #modalContainer
    class="mat-typography content">
    <p
      *ngIf="formState.success === undefined"
      class="subtitle">
      {{ data.labels['supportform.subtitle'] }}
    </p>

    <div *ngIf="formState.success === undefined">
      <div class="form-container">
        <div class="row">
          <div class="col-12 col-xl-6 form-field">
            <label
              for="name"
              class="form-group-label">
              <strong>{{ data.labels['supportform.label.contact-name'] }}</strong>
            </label>
            <input
              id="name"
              type="text"
              [attr.aria-label]="data.labels['supportform.label.contact-name']"
              formControlName="contactName"
              readonly>
          </div>

          <div class="col-12 col-xl-6 form-field">
            <label
              for="email"
              class="form-group-label">
              <strong>{{ data.labels['supportform.label.contact-email'] }}</strong>
            </label>
            <input
              id="email"
              type="email"
              [attr.aria-label]="data.labels['supportform.label.contact-email']"
              formControlName="email"
              readonly>
          </div>
        </div>

        <div class="row">
          <div class="col-12 form-field">
            <label
              for="additionalEmails"
              class="form-group-label">
              <strong>{{ data.labels['supportform.label.additional-emails'] }}</strong>
            </label>
            <input
              id="additionalEmails"
              [ngClass]="{ 'has-error': hasFormError('additionalEmails') }"
              type="text"
              [attr.aria-label]="data.labels['supportform.label.additional-emails']"
              formControlName="additionalEmails">
              <p class="input__hint">{{ data.labels['supportform.hint.additional-emails'] }}</p>
            <div
              class="error-message"
              role="alert"
              aria-live="assertive"
              [hidden]="!hasFormError('additionalEmails')">
              <span>
                {{
                  form.get('additionalEmails').errors?.invalidAdditionalEmails
                    ? data.labels['supportform.error.additional-emails']
                      +
                      ' '
                      +
                      form.get('additionalEmails').errors.invalidAdditionalEmails.join(', ')
                    : null
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="form-field">
          <label
            for="issue"
            class="form-group-label required">
            <strong>{{ data.labels['supportform.label.experiencing-issue'] }}</strong>
          </label>
          <textarea
            id="issue"
            [ngClass]="{ 'has-error': hasFormError('issue') }"
            [placeholder]="data.labels['supportform.placeholder.experiencing-issue']"
            [attr.aria-label]="data.labels['supportform.placeholder.experiencing-issue']"
            rows="5"
            formControlName="issue"
            required
          ></textarea>
          <p class="input__hint no-margin">{{ data.labels['supportform.hint.experiencing-issue'] }}</p>
          <div
            class="error-message"
            role="alert"
            aria-live="assertive"
            [hidden]="!hasFormError('issue')">
            <span>
              {{
                form.get('issue').hasError('required')
                  ? data.labels['supportform.error.field-required']
                  : null
              }}
            </span>
          </div>
        </div>

        <div class="form-field">
          <label
            for="tradingpartners"
            class="form-group-label required">
            <strong>{{ data.labels['supportform.label.tradingpartners'] }}</strong>
          </label>
          <input
            id="tradingpartners"
            [ngClass]="{ 'has-error': hasFormError('tradingPartners') }"
            type="text"
            [attr.aria-label]="data.labels['supportform.label.tradingpartners']"
            formControlName="tradingPartners"
            required>
            <p class="input__hint">{{ data.labels['supportform.hint.tradingpartners'] }}</p>
          <div
            class="error-message"
            role="alert"
            aria-live="assertive"
            [hidden]="!hasFormError('tradingPartners')">
            <span>
              {{
                form.get('tradingPartners').hasError('required')
                  ? data.labels['supportform.error.field-required']
                  : null
              }}
            </span>
          </div>
        </div>

        <div class="form-field">
          <label class="form-group-label required">
            <strong>{{ data.labels['supportform.label.percentage'] }}</strong>
          </label>
          <mat-radio-group
            formControlName="percentage"
            class="radio-group"
            [attr.aria-label]="data.labels['supportform.aria-label.select-option']">
            <mat-radio-button
              class="radio-group__option"
              value="lessThan25">
              {{ data.labels['supportform.label.percentage_lessThan25'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="between25and50">
              {{ data.labels['supportform.label.percentage_between25and50'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="between50and75">
              {{ data.labels['supportform.label.percentage_between50and75'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="moreThan75">
              {{ data.labels['supportform.label.percentage_moreThan75'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="nA">
              {{ data.labels['supportform.label.percentage_notApplicable'] }}
            </mat-radio-button>
          </mat-radio-group>
          <div
            class="error-message"
            role="alert"
            aria-live="assertive"
            [hidden]="!hasFormError('percentage')">
            <span>
              {{
                form.get('percentage').hasError('required')
                  ? data.labels['supportform.error.field-required']
                  : null
              }}
            </span>
          </div>
        </div>

        <div class="form-field">
          <label class="form-group-label required">
            <strong>{{ data.labels['supportform.label.daily-business'] }}</strong>
          </label>
          <mat-radio-group
            formControlName="dailyBusiness"
            class="radio-group"
            [attr.aria-label]="data.labels['supportform.aria-label.select-option']">
            <mat-radio-button
              class="radio-group__option"
              value="no">
              {{ data.labels['supportform.label.daily-business_no'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="yesBut">
              {{ data.labels['supportform.label.daily-business_workaround'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="yes">
              {{ data.labels['supportform.label.daily-business_yes'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="cannotConductBusiness">
              {{ data.labels['supportform.label.daily-business_cannot_conduct_business'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="nA">
              {{ data.labels['supportform.label.daily-business_notApplicable'] }}
            </mat-radio-button>
          </mat-radio-group>
          <div
            class="error-message"
            role="alert"
            aria-live="assertive"
            [hidden]="!hasFormError('dailyBusiness')">
            <span>
              {{
                form.get('dailyBusiness').hasError('required')
                  ? data.labels['supportform.error.field-required']
                  : null
              }}
            </span>
          </div>
        </div>

        <div class="form-field">
          <label class="form-group-label required">
            <strong>{{ data.labels['supportform.label.critical-business'] }}</strong>
          </label>
          <mat-radio-group
            formControlName="criticalBusiness"
            class="radio-group"
            [attr.aria-label]="data.labels['supportform.aria-label.select-option']">
            <mat-radio-button
              class="radio-group__option"
              value="immediately">
              {{ data.labels['supportform.label.critical-business_immediately'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="within24hours">
              {{ data.labels['supportform.label.critical-business_within24hrs'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="within48hours">
              {{ data.labels['supportform.label.critical-business_within48hrs'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="moreThan48hours">
              {{ data.labels['supportform.label.critical-business_moreThan48hrs'] }}
            </mat-radio-button>
            <mat-radio-button
              class="radio-group__option"
              value="noPotentialImpact">
              {{ data.labels['supportform.label.critical-business_noImpact'] }}
            </mat-radio-button>
          </mat-radio-group>
          <div
            class="error-message"
            role="alert"
            aria-live="assertive"
            [hidden]="!hasFormError('criticalBusiness')">
            <span>
              {{
                form.get('criticalBusiness').hasError('required')
                  ? data.labels['supportform.error.field-required']
                  : null
              }}
            </span>
          </div>
        </div>
      </div>

      <flow-file-upload
        [config]="data.config"
        [labels]="data.labels"
        (attachedFiles)="onFiles($event)"
        [hideUploadErrorToggle]="hideUploadErrorToggle"
      ></flow-file-upload>
    </div>

    <div
      *ngIf="formState.success !== undefined"
      class="form-sent-container">
      <div *ngIf="formState.success" class="success">
        <i
          class="material-icons"
          [attr.aria-label]="data.labels['supportform.aria-label.success']">
          mail_outline
        </i>
        <h2>{{ data.labels['supportform.title.success'] }}</h2>
        <p>{{ data.labels['supportform.subtitle.success'] }}</p>
      </div>

      <div *ngIf="!formState.success" class="failure">
        <i
          class="material-icons"
          [attr.aria-label]="data.labels['supportform.aria-label.failure']">
          error_outline
        </i>
        <h2>{{ data.labels['supportform.title.failure'] }}</h2>
        <button
          class="button button-primary"
          type="button"
          [attr.aria-label]="data.labels['supportform.btn.try.again']"
          (click)="onRetry()">
          {{ data.labels['supportform.btn.try.again'] }}
        </button>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    class="footer"
    [ngClass]="{ 'sent': formState.success !== undefined, 'has-custom-help': hasCustomHelpSite }">

    <button
      *ngIf="hasCustomHelpSite"
      class="button button-primary"
      type="button"
      [attr.aria-label]="data.labels['supportform.btn.help']"
      (click)="openCustomHelpSite()">
      {{ data.labels['supportform.btn.help'] }}
    </button>

    <div class="form-buttons">
      <button
        class="button"
        type="button"
        (click)="onClose()"
        [attr.aria-label]="formState.success === undefined ? data.labels['supportform.btn.cancel'] : data.labels['supportform.btn.close']">
        {{ formState.success === undefined ? data.labels['supportform.btn.cancel'] : data.labels['supportform.btn.close'] }}
      </button>

      <button
        *ngIf="formState.success === undefined"
        class="button button-primary ml-md-2"
        [disabled]="formState.saving"
        type="submit"
        [attr.aria-label]="data.labels['supportform.btn.submit']">
        <span *ngIf="!formState.saving">{{ data.labels['supportform.btn.submit'] }}</span>
        <span *ngIf="formState.saving" class="saving"></span>
      </button>
    </div>
  </mat-dialog-actions>
</form>
