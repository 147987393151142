import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  OnInit,
} from '@angular/core';

import { Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlowEnvService } from '@flow/core';

import { FlowMarketplaceStateInterface } from '@marketplaceStore/reducers';
import { getIsHelpPanelOpen } from '@marketplaceStore/selectors';

@Component({
  selector: 'flow-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowHeaderComponent implements OnInit {
  @HostBinding('class') classNames = 'd-flex flex-column';

  isStandardEinvoicingProject: boolean;
  browserTitle: string;

  helpPanelVisible$: Observable<string>;

  constructor(
    private title: Title,
    private store: Store<FlowMarketplaceStateInterface>,
    private EnvService: FlowEnvService
  ) {}

  ngOnInit(): void {
    this.isStandardEinvoicingProject = this.EnvService.isStandardEinvoicingProject;
    this.isStandardEinvoicingProject && (this.browserTitle = this.title.getTitle());

    this.helpPanelVisible$ = this.store.pipe(
      select(getIsHelpPanelOpen),
      map((status) => (status === true ? 'yes' : 'no'))
    );

    /* NOTE:
            - ONLY for local DEV.
            - Use this to get & test 500 error response (from 3rd-party service).
            - This (Test-B) will trigger only in login mode.

            - Requires:
              - import:
                  import {HttpClient} from "@angular/common/http";
              - constructor:
                  private httpClient: HttpClient,
    */
    /*this.httpClient.get('https://tfmp500.free.beeceptor.com').subscribe(
      r => {
        console.warn('%c 500 ERROR test-B. The 3rd-party needs to be configured ', 'background:green', r);
      },
      err => { console.warn('%c 500 ERROR test-B err', 'background:magenta', err); }
    );*/
  }
}
