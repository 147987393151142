import { Injectable } from '@angular/core';

import { FlowEnvService } from '@flow/core';

const DM_API_URLS = {
  test:       'https://docman-internal-api.azurewebsites.net/',
  preAccept:  'https://docman-eu-api-test.tiekinetix.net/',
  accept:     'https://docman-eu-api-test.tiekinetix.net/',
  prod:       'https://docman-eu-api.tiekinetix.net/'
};

const DM_CSS_FILE = 'SPS.TK.DocumentManager.UI.styles.css';
const DM_JS_FILE = '_framework/blazor.webassembly.js';

@Injectable({
  providedIn: 'root'
})
export class FlowDmcAssetsLoaderService {
  private _apiUrl;

  constructor(
    private EnvService: FlowEnvService
  ) {
    this._apiUrl = this._getApiUrl();

    // Set up the API URL
    document['dmApiUrl'] = this._apiUrl;
  }

  loadScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const src = `${ this._apiUrl }${ DM_JS_FILE }`;

      script.src = src
      script.type = 'text/javascript';
      script.nonce = '**CSP_NONCE**';
      script.async = true;

      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Script load error for ${src}`));

      document.body.appendChild(script);
    });
  }

  loadStyles(): Promise<void> {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link');
      const href = `${ this._apiUrl }${ DM_CSS_FILE }`;

      link.rel = 'stylesheet';
      link.nonce = '**CSP_NONCE**';
      link.href = href;

      link.onload = () => resolve();
      link.onerror = () => reject(new Error(`Stylesheet load error for ${href}`));

      document.head.appendChild(link);
    });
  }

  private _getApiUrl(): string {
    let dmApiUrl;

    switch (true) {
      case this.EnvService.isProduction:
        dmApiUrl = DM_API_URLS.prod;
        break;

      case this.EnvService.isAcceptance:
        dmApiUrl = DM_API_URLS.accept;
        break;

      case this.EnvService.isPreAccept:
        dmApiUrl = DM_API_URLS.preAccept;
        break;

      default:
        dmApiUrl = DM_API_URLS.test;
    }

    return dmApiUrl;
  }
}
