import { Injectable } from '@angular/core';

const PATTERNS = {
  at_cid:             '(AT)[0-9]{2}[A-Z0-9]{3}0[0-9]{10}',
  at_gov:             '[A-Z][A-Z0-9]*',
  at_kur:             '[A-Z][0-9]{3}[A-Z][0-9]{3}[A-Z]',
  be_cbe:             '0[0-9]{9}',
  de_lid:             '[0-9]{0,12}(-[0-9a-zA-Z]{0,30}(-[0-9]{2}))',
  de_tax:             '[0-9]{10,13}',
  dk_cpr:             '(([1-2][0-9])|0([1-9])|(3[0-1]))((1[0-2])|(0[1-9]))[0-9]{2}-[1-9][0-9]{3}',
  dk_cvr:             '[1-9][0-9]{7}',
  dk_digst:           '[0-9]{8}([0-9]{2})?',
  dk_p_number:        '[0-9]{17}',
  dk_se:              'DK[0-9]{8}',
  dk_vans:            'DK[0-9]{8}',
  duns_number:        '[0-9]{9}',
  ee_rik:             '[0-9]{8}',
  email:              '[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}',
  emailNameWildcard:  '\\*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}',
  fi_ovt:             '0037[0-9]{8}[0-9A-Z]{0,5}',
  fr_siren:           '[0-9]{9}([0-9]{5})?',
  fr_siret:           '[0-9]{14}',
  gln:                '[0-9]{13}',
  guid:               '({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}',
  is_kt:              '[0-9]{10}',
  it_cf:              '[0-9]{11}',
  it_fti:             '[0-9]{11,16}',
  it_ipa:             '[0-9a-zA-Z]{6}',
  nl_oin:             '[0-9]{20}',
  no_org:             '[0-9]{9}',
  no_orgnr:           '[0-9]{9}',
  phone_number:       '\\+\\d{7,14}',
  se_orgnr:           '[0-9]{10}',
  sg_uen:             '((S|T)([\\d]{2})([A-Z]{2})([\\d]{4})([A-Z])|(\\d{8,9})([A-Z]))',
  url:                '(https?:\/\/((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,63}([-A-Za-z0-9@:%._\+~#?&\/\/=]*))',
};


@Injectable({
  providedIn: 'root'
})
export class FlowRegexService {

  getPattern(pattern: string): string {
    return PATTERNS[pattern];
  }

  getRegex(pattern: string): RegExp {
    const foundPattern = PATTERNS[pattern];
    return foundPattern && new RegExp(`^${foundPattern}$`);
  }

  isValid(pattern: string, value: string): boolean {
    const regex = this.getRegex(pattern);
    return regex && regex.test(value);
  }
}
