<div class="single-page">
  <div class="background"></div>
  <div class="container br-3 mb-5 panel">
    <div class="text-right pos-absolute" style="right: 0">
      <a routerLink="/auth/logout" class="logoutLink button text-color-blue">
        <i class="material-icons color-danger">power_settings_new</i>
        {{ labels['general.label.logout'] }}
      </a>
    </div>

    <div class="content-wrapper text-center">
      <div class="d-flex justify-content-center mb-5">
        <flow-portal-logo [backgroundPosition]="['center']"> </flow-portal-logo>
      </div>

      <h3 class="pb-1 text-color-blue">
        {{ labels['general.label.missing_info'] }}
      </h3>
      <p class="text-color-blue">
        {{ labels['general.label.complete_missing_info'] }}
      </p>

      <div class="text-left mt-5">
        <flow-missing-info-form></flow-missing-info-form>
      </div>
    </div>
  </div>
</div>
<flow-footer></flow-footer>
