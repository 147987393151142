@if (loading$ | async) {
  <flow-preloader  />
} @else {
  @if (errorMsg$ | async; as errorMsg) {
    <div class="p-3 modal-title bg-primary color-white">
      <h5 [innerHTML]="dialogData['error.modal.error.title']"></h5>
    </div>

    <mat-dialog-content class="mb-3">
      <div class="alert danger" [innerHTML]="errorMsg"></div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        class="button button-primary"
        (click)="cancel()"
        [innerHTML]="dialogData.cancelOnErrorButtonLabel"
      ></button>
    </mat-dialog-actions>
  } @else {
    @if (dialogData.title) {
      <div class="p-3 modal-title bg-primary color-white">
        <h5 class="truncate" [innerHTML]="dialogData.title"></h5>
      </div>
    }
    @if (deleting$ | async) {
      <flow-preloader [classes]="'mt-4 mb-4'" />
    } @else {
      @if (dialogData.content) {
        <mat-dialog-content>
          <div [innerHTML]="dialogData.content | safe:'html'"></div>
        </mat-dialog-content>
      }
      <mat-dialog-actions>
        <button
          class="button button-close-dialogs"
          (click)="cancel()"
          [innerHTML]="dialogData.cancelButtonLabel"
        ></button>
        <button
          class="button button-primary ml-2"
          (click)="deleteEntity()"
          [innerHTML]="dialogData.okButtonLabel"
        ></button>
      </mat-dialog-actions>
    }
  }
}
