/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { LANGUAGES } from '../../constants/languages';
import { COUNTRIES } from '../../constants/countries';
import { ROLES } from '../../constants/roles';

import { FlowLanguageInterface } from '../../interfaces/language.interface';
import { FlowCountryInterface } from '../../interfaces/country.interface';
import { FlowHelpers } from '../../classes/class.helpers';
import { FlowTimezoneInterface } from '../../interfaces/timezone.interface';
import { TIMEZONES } from '../../constants/timezones';

const HTTP = 'http://';
const HTTPS = 'https://';

@Injectable({
  providedIn: 'root'
})
export class FlowUtilsService {

  readonly _languages: FlowLanguageInterface[];
  readonly _countries: FlowCountryInterface[];
  readonly _timezones: FlowTimezoneInterface[];

  constructor(
  ) {
    // Sort languages so we don't need to sort in each iteration.
    this._languages = LANGUAGES.sort((a, b) => a.description.localeCompare(b.description));

    // Sort countries so we don't need to sort in each iteration.
    this._countries = COUNTRIES.sort((a, b) => a.name.localeCompare(b.name));

    // Sort timezones so we don't need to sort in each iteration.
    this._timezones = TIMEZONES.sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * Similar to lodash _.find
   * Given an array of objects, return an object that has certain `property` with certain `value`.
   */
  findIn(predicate: any[], property: string, value: any): any {
    if (!this.isArray(predicate)) {
      return false;
    }
    return predicate.find((element) => element[property] === value);
  }

  /**
   * Given an Object, check if it has a given property.
   */
  hasProperty(object: any, key: string): boolean {
    return FlowHelpers.hasProperty(object, key);
  }

  /**
   * Given a predicate, check if it's Array.
   */
  isArray(predicate: any): boolean {
   return FlowHelpers.isArray(predicate);
  }

  /**
   * Given a predicate, check if it's an Object.
   */
  isObject(predicate: any): boolean {
    return FlowHelpers.isObject(predicate);
  }

  /**
   * Given a predicate, check if it's an Object and if it's not empty.
   */
  isEmptyObject(predicate: any): boolean {
    return FlowHelpers.isEmptyObject(predicate);
  }

  /**
   * Given a predicate, check if it's Array and if it's not empty.
   */
  isEmptyArray(predicate: any[]): boolean {
    return FlowHelpers.isEmptyArray(predicate);
  }

  /**
   * Utility function to check for prototype pollution in objects.
   */
  isSafeKey(key: string): boolean {
    const unsafeKeys = ['__proto__', 'constructor', 'prototype'];
    return !unsafeKeys.includes(key);
  }

  /**
   * Gets all the available languages.
   */
  getLanguages(): FlowLanguageInterface[] {
    return this._languages;
  }

  /**
   * Gets all the available countries
   */

  //
  // DEPRECATED: Don't use this anymore!
  // Use FlowCountriesService from @flow/shared instead.
  //
  getCountries(): FlowCountryInterface[] {
    return this._countries;
  }

  /**
   * Gets all the available roles.
   */
  getAvailRoles() {
    return ROLES;
  }

  /**
   * Getter for the timezones.
   */
  getTimezones(): FlowTimezoneInterface[] {
    return this._timezones;
  }

  /**
   * Get a timezone by name
   */
  getTimeZoneByName(name: string): FlowTimezoneInterface {
    return this._timezones.find(timezone => timezone.name === name);
  }

  /**
   * Returns the language name based on language code.
   */
  getLanguageName(code: string): any {
    if (!code) {
      return null;
    }

    return this._languages
      .filter(language => language.lcId.toLowerCase() === code.toLowerCase())
      .map(result => result.description);
  }

  /**
   * Checks if a given language code exists and returns it.
   */
  getLanguageByCode(code: string): string|null {
    if (!code) {
      return null;
    }

    // Previous solution didn't work correctly for simple language codes like
    // nl, de, es, ...
    // as "this._languages" only uses nl-NL, de-DE, es-ES, ...
    // This resulted always in the use of the default language en-US.

    /* return this._languages.find(language => {
      return language.lcId.toLowerCase() === code.toLowerCase();
    }) ? true : false; */

    // New solution tries to find proper codes used in "this._languages"
    // e.g. nl -> nl-NL, de -> de-DE, es -> es-ES, ...
    // and returns them, so they can be used to store in local storage.

    const foundLanguage = this._languages
      .find(language => language.lcId.toLowerCase().indexOf(code.toLowerCase()) > -1);

    return foundLanguage ? foundLanguage.lcId : null;
  }

  /**
   * Utilities for parsing the `extra` key in some of the models/objects
   */
  hasExtra(where: any[], key: string, property = 'PropertyName'): boolean {
    if (!this.isArray(where) || this.isEmptyArray(where)) {
      return false;
    }

    return !!this.findIn(where, property, key);
  }

  /**
   * Generate password with given length
   *
   * @param length
   */
  generatePassword(length = 8): string {
    const ALPHA = 'abcdefghijklmnopqrstuvwxyz';
    const CHARS = {
      alpha: ALPHA,
      caps: ALPHA.toUpperCase(),
      nums: '0123456789',
      symbols: '!@#$%^&*'
    };
    const OPTIONS = ['alpha', 'caps', 'nums', 'symbols'];

    let includes = [];
    let string;
    let password = '';

    const _randomize = (len: number) => Math.floor(Math.random() * len);

    while (length--) {
      !includes.length && (includes = OPTIONS.concat());

      string = CHARS[ includes.splice(_randomize(includes.length), 1)[0] ];
      password += string.charAt(_randomize(string.length));
    }

    return password;
  }

  /**
   * Gets an extra from an array of objects
   */
  getExtra(where: any[], key: string, property = 'PropertyName') {
    return this.findIn(where, property, key);
  }

  /**
   * Formats the value to primitive booleans from an extra.
   */
  getExtraValue(where: any[], key: string, property?: string): any {
    const extra = this.getExtra(where, key, property);
    if (this.isObject(extra) && this.hasProperty(extra, 'Value')) {
      return this.parseToPrimitive(extra['Value']);
    }

    return false;
  }

  parseToPrimitive(value: string): any {
    return FlowHelpers.parseToPrimitive(value);
  }

  isBooleanLike(value: string): boolean {
    return FlowHelpers.isBooleanLike(value);
  }


  isNumberLike(value: any): boolean {
    return FlowHelpers.isNumberLike(value);
  }

  /**
   * Creates a timestamp to avoid cached results problems.
   */
  timestamp(): number {
    return FlowHelpers.timestamp();
  }

  /**
   * Returns the edit mode on a detail view
   */
  getDetailViewEditMode(snapshot: ActivatedRouteSnapshot): string {
    if (this.hasProperty(snapshot.params, 'id')) {
      if (snapshot.params.id === 'new') {
        return 'new';
      }
      else if (!this.isEmptyArray(snapshot.children) &&
                 this.hasProperty(snapshot.children[0].params, 'mode') &&
                 snapshot.children[0].params.mode === 'copy')  {
        return 'copy';
      }
      else {
        return 'edit';
      }
    }

    return 'noop';
  }

  /**
   * Creates a globally unique identifier (GUID)
   */
  createGuid(): string {
    return FlowHelpers.createGuid();
  }

  /**
   * Given that we have buckets where the vendor name is lowercased and others where is not,
   * whitelist certain project(s) to get the correct name.
   */
  vendorName(vendorId: string): string {
    return FlowHelpers.vendorName(vendorId);
  }

  /**
   * Removes empty values from object and returns new object
   */
  removeEmptyObjectValues(predicate: any): any {
    return FlowHelpers.removeEmptyObjectValues(predicate);
  }

  /**
   * Check if a url string contains either http:// or https:// prefix
   */
  hasHttpPrefix(url: string) {
    return url.includes(HTTP) || url.includes(HTTPS);
  }

  /**
   * Adds http:// prefix to url string if it does contain either http:// or https:// prefix
   */
  addHttpPrefix(url: string) {
    return this.hasHttpPrefix(url) ? url : HTTP + url;
  }

  /**
   * Remove all whitespaces
   */
   trimAll(value: string): string {
    return value.replace(/\s/g,'');
  }

  /**
   * Check for Safari browser
   */
  isSafari() {
    return navigator.userAgent.indexOf('Safari') !== -1
           &&
           navigator.userAgent.indexOf('Chrome') === -1;
  }

  /**
   * Decode encoded HTML string
   */
  decodeHtml(html: string): string {
    const textarea = document.createElement('textarea');

    let textAreaValue;

    // In case any & are also encoded.
    html = html.replace(/&amp;/g, '&');

    textarea.innerHTML = html;

    textAreaValue = textarea.value;

    // In case any src property was removed from img tags.
    textAreaValue = textAreaValue.replace(/<img "data:/g, '<img src="data:');

    return textAreaValue;
  }
}
