import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges

} from '@angular/core';

import { FlowDateLocalePipe } from '@flow/shared';

import { FlowSupportWidgetConfig, FlowSupportWidgetLabels } from '../../interfaces';

@Component({
  selector: 'flow-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ FlowDateLocalePipe ]
})
export class FlowFileUploadComponent implements OnInit, OnChanges {

  @Input() config: FlowSupportWidgetConfig;

  @Input() labels: FlowSupportWidgetLabels;

  @Input() hideUploadErrorToggle: boolean;

  @Output() attachedFiles = new EventEmitter();

  files: File[] = [];

  maxFiles: number;
  allowedFileTypes: string;
  allowedFileExtensions: string;
  allowedMimeTypes: string;
  maxUploadSize: number;
  maxUploadSizeFormatted: string;

  uploadState = {
    isOnDropzone: false,
    isUploadError: false,
    uploadErrors: 0,
    uploadErrorsMessage: '',
    failedFileNames: []
  };

  ngOnInit(): void {
    this.maxFiles = this.config.maxFiles || 1;

    this.allowedFileExtensions = this.config.allowedFileExtensions ? this.config.allowedFileExtensions.join(', ') : undefined;
    this.allowedMimeTypes = this.config.allowedMimeTypes ? this.config.allowedMimeTypes.join(', ') : undefined;

    this.allowedFileTypes = (this.allowedFileExtensions && this.allowedMimeTypes)
      ? this.allowedFileExtensions + ', ' + this.allowedMimeTypes
      : undefined;

    this.maxUploadSize = this.config.maxUploadSize || undefined;
    this.maxUploadSizeFormatted = this.maxUploadSize ? this._convertFileSize(this.maxUploadSize) : undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes.hideUploadErrorToggle && this._resetUploadErrors();
  }

  handleFiles(files: File[]) {
    const filesMarkedForUpload = [];

    // Reset any previous upload errors
    this._resetUploadErrors();

    if (this._validatedAll(files)) {
      for (const file of files) {
        if (this._validatedFile(file)) {
          filesMarkedForUpload.push(file);
        }
        else {
          this.uploadState.isUploadError = true;
          this.uploadState.failedFileNames.push(file.name);
        }
      }

      // Handle upload errors
      if (this.uploadState.isUploadError) {
        this.uploadState.uploadErrors = this.uploadState.failedFileNames.length;
      }
      // Handle successful uploads
      else {
        if (filesMarkedForUpload.length === files.length) {
          this.files = [ ...this.files, ...filesMarkedForUpload ];

          this.attachedFiles.emit(this.files);
        }
      }
    }
  }

  onFileSelected($event: Event) {
    const inputElement = $event.target as HTMLInputElement;

    this.handleFiles(Array.from(inputElement.files));

    // Reset the file input value to ensure the change event is triggered again
    inputElement.value = '';
  }

  onDropZoneClick() {
    // Reset any previous upload errors
    this._resetUploadErrors();
  }

  onDrop($event: DragEvent) {
    $event.preventDefault();

    this.uploadState.isOnDropzone = false;

    this.handleFiles(Array.from($event.dataTransfer.files));
  }

  onDragLeave($event: DragEvent) {
    $event.preventDefault();

    this.uploadState.isOnDropzone = false;
  }

  onDragOver($event: DragEvent) {
    $event.preventDefault();

    // Reset any previous upload errors
    this._resetUploadErrors();

    this.uploadState.isOnDropzone = true;
  }

  removeFile(index: number) {
    // Remove file
    this.files.splice(index, 1);

    // Reset any previous upload errors
    this._resetUploadErrors();

    this.attachedFiles.emit(this.files);
  }

  private _convertFileSize(bytes: number): string {
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  private _interpolateLabel(label: string, key: string, value: string): string {
    return label.replace(key, value);
  }

  private _resetUploadErrors() {
    this.uploadState.isUploadError = false;
    this.uploadState.uploadErrors = 0;
    this.uploadState.uploadErrorsMessage = '';
    this.uploadState.failedFileNames = [];
  }

  private _validatedAll(files: File[]): boolean {
    // Check for maximum upload size
    const totalUploadSize = this.files.reduce((acc, file) => acc + file.size, 0) + files.reduce((acc, file) => acc + file.size, 0);

    if (totalUploadSize > this.maxUploadSize) {
        this.uploadState.isUploadError = true;
        this.uploadState.uploadErrors = 1;
        this.uploadState.uploadErrorsMessage = this._interpolateLabel(
          this.labels['supportform.error.max-file-size-exceeded'],
          '{{maxFileSize}}',
          this.maxUploadSizeFormatted
        );

        return false;
    }

    // Check for maximum files
    else if (this.files.length + files.length > this.maxFiles) {
      this.uploadState.isUploadError = true;
      this.uploadState.uploadErrors = 1;

      this.uploadState.uploadErrorsMessage = this._interpolateLabel(
        this.labels['supportform.error.max-files-exceeded'],
        '{{maxFiles}}',
        this.maxFiles.toString()
      );

      return false;
  }

    return true;
}

  private _validatedFile(file: any): boolean {
    const fileTypeValid = this.allowedFileTypes.split(',').some(type => file.name.toLowerCase().endsWith(type.trim()));
    const fileSizeValid = file.size <= this.maxUploadSize;

    return fileTypeValid && fileSizeValid;
  }
}
