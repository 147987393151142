import { Pipe, PipeTransform } from '@angular/core';

import { FlowSupportWidgetLabels } from '../interfaces';

@Pipe({
  name: 'fileType'
})
export class FlowFileTypePipe implements PipeTransform {

  transform(value: string, labels: FlowSupportWidgetLabels): string {
    let type = '';

    if (value) {
      const fileExtension = value.split('.').pop().toLowerCase();

      switch (fileExtension) {
        case 'doc':
        case 'docx':
          type = labels['supportform.label.file-preview.type_word'];
          break;

        case 'pdf':
          type = 'PDF';
          break;

        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'gif':
          type = labels['supportform.label.file-preview.type_image'];
          break;

        case 'txt':
          type = labels['supportform.label.file-preview.type_text'];
          break;

        case 'csv':
          type = 'CSV';
          break;

        case 'xls':
        case 'xlsx':
          type = labels['supportform.label.file-preview.type_spredsheet'];
          break;

        default:
          type = '';
          break;
      }
    }

    return type;
  }
}