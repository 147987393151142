<div class="support-bg"></div>
<div class="support-wrapper">
  <div class="support-container">
    <div class="logos">
      <div class="flow-logo"></div>
      <div class="tiekinetix-logo"
        flowBackgroundImage
        [imageUrl]="'assets/images/tie_kinetix_sps_commerce_logo.svg'"
        [backgroundSizeType]="'contain'"
        [backgroundPosition]="['center', 'right']">
      </div>
    </div>
    <div class="title">
      <h2>{{ labels['support.label.page_title'] }}</h2>
      <ng-container *ngIf="!hideButtons">
        <p>{{ labels['support.label.page_introduction'] }}</p>
        <a
          href="../auth/login"
          class="button button-primary">
          {{ labels['general.btn.login'] }}
        </a>
      </ng-container>
    </div>

    <section class="section__request">
      <h3>{{ labels['support.label.submit_request.title'] }}</h3>
      <div class="large align-items-center mb-3">
        <p>{{ labels['support.label.login.description_a'] }}</p>
        <i class="material-icons icon">help</i>
        <p>{{ labels['support.label.login.description_b'] }}</p>
      </div>
      <div class="small mb-3">
        <div>
          <p>{{ labels['support.label.login.description_a'] }} {{ labels['support.label.login.description_b'] }}</p>
        </div>
        <div>
          <i class="material-icons icon">help</i>
        </div>
      </div>
      <!-- <p class="mb-3">{{ labels['support.label.submit_request.description'] }}</p> -->
    </section>

    <!-- <section class="section__knowledge_base d-lg-flex justifiy-content-between">
      <div class="info">
        <h3>{{ labels['support.label.knowledge_base.title'] }}</h3>
        <p>{{ labels['support.label.knowledge_base.description'] }}</p>
      </div>
      <div class="zendesk-image"></div>
    </section> -->

    <section class="section__guide d-lg-flex justifiy-content-between">
      <div class="info">
        <h3>{{ labels['support.label.guide.title'] }}</h3>
        <div class="large justifiy-content-end mb-3">
          <div>
            <p>{{ labels['support.label.guide.description_a'] }}</p>
            <p>{{ labels['support.label.login.description_b'] }}</p>
          </div>
          <i class="material-icons icon guide">explore</i>
        </div>
        <div class="small mb-3">
          <div>
            <p>{{ labels['support.label.guide.description_a'] }} {{ labels['support.label.login.description_b'] }}</p>
          </div>
          <div>
            <i class="material-icons icon guide">explore</i>
          </div>
        </div>
        <p>{{ labels['support.label.guide.description'] }}</p>
      </div>
      <div class="userlane-image"></div>
    </section>

    <section class="section__status">
      <h3>{{ labels['support.label.system_status.title'] }}</h3>
      <p [ngClass]="{ 'mb-0': hideButtons }">
        {{ !hideButtons
            ? labels['support.label.system_status.description']
            : labels['support.label.system_status_without_link.description']
        }}
      </p>
      <a
        *ngIf="!hideButtons"
        href="https://flow-status.tiekinetix.net/"
        class="button button-primary gray support">
        {{ labels['support.label.system_status.button'] }}
      </a>
    </section>
  </div>
</div>

<flow-footer></flow-footer>
