<footer class="d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center content-left hidden-mobile">
    @if (isStandardEinvoicingProject) {
      <img src="/assets/images/sps-mark-gray.svg" alt="sps-logo" class="sps-logo"/>
      <div class="brand">
        {{ spsCompanyName }}
      </div>
    } @else () {
      <flow-logo
        [width]="'70'"
        [height]="'25'"
        [viewbox]="'0 0 140 30'"
        [classes]="logoClasses"
      ></flow-logo>
      <div class="brand">Partner Automation</div>
    }
  </div>

  <div class="content-right">
    <ul class="list-inline">
      <li>&copy; {{ currentYear }} {{ spsCompanyName }}</li>

      @if (showLinks) {
        @if (isSupplierPortal$ | async) {
          @if (
            privacySettings &&
            privacySettings.url &&
            privacySettings.view
            ) {
            <li>
              @if (privacySettings.view === 'browser') {
                <a
                  [href]="privacySettings.url"
                  [innerHTML]="labels['general.footer.link.disclaimer']"
                  target="_blank"
                ></a>
              }
              @if (privacySettings.view === 'modal') {
                <a
                  (click)="openCustomerPrivacyModal(privacySettings.url)"
                  [innerHTML]="labels['general.footer.link.disclaimer']"
                ></a>
              }
            </li>
          }
        } @else () {
          <li>
            <a
              (click)="openPrivacyModal()"
              [innerHTML]="labels['general.footer.link.disclaimer']"
            ></a>
          </li>
        }
      }

      @if (showLinks) {
        <li>
          <a
            href="http://flow-status.tiekinetix.net/"
            [innerHTML]="labels['general.footer.link.status_page']"
            target="_blank"
          ></a>
        </li>
      }
    </ul>
  </div>
</footer>
