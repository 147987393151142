@if (!isMobile && isStandardEinvoicingProject) {
  <a
    class="sps-navbar__brand"
    [routerLink]="redirectUrl">
    <img
      class="sps-navbar__brand-logo"
      src="/assets/images/sps-einvoicing-logo-dblu.svg"
      aria-hidden="true"
      [alt]="browserTitle" />
    <span class="sps-navbar__brand-name">{{ browserTitle }}</span>
  </a>
  <span class="sps-vertical-rule"></span>
}

@if (!hideDashboard) {
  <nav class="tabs tabs-nav-overflow">
    <flow-menu-main-bookmark
      [bookmarkType]="'dashboard'"
      [extraClass]="'bookmark-visible'"
      [routerLink]="['/dashboard']"
      [routerLinkActive]="'current-menu-item'"
      [icon]="'dashboard'"
      [label]="labels['account_settings.label.dashboard']">
    </flow-menu-main-bookmark>
  </nav>
}

<flow-menu-main-bookmarks
  [bookmarks]="bookmarks$ | async"
  [boughtProducts]="boughtProducts$ | async"
  (bookmarksChanged)="bookmarksChanged($event)">
</flow-menu-main-bookmarks>

<nav class="tabs tabs-nav-overflow">
  @if (hiddenBookmarks.length) {
    <flow-menu-main-bookmark
      [bookmarkType]="'more'"
      [extraClass]="'bookmark-visible'"
      [label]="labels['general.label.more']"
      [matMenuTriggerFor]="appMenu"
      [icon]="'more_vert'">
    </flow-menu-main-bookmark>

    <mat-menu
      #appMenu="matMenu"
      [class]="'bookmarks-menu'">
      @for (bookmark of hiddenBookmarks; track bookmark.id; let i = $index) {
        <button
          mat-menu-item
          (click)="openSolution(bookmark, i)"
        >
          @if (!isStandardEinvoicingProject && !bookmark.hideIcon) {
            <i class="flow-icon mr-1" [ngClass]="'icon-' + bookmark.id "></i>
          }
          <span>{{ bookmark.name }}</span>
        </button>
      }
    </mat-menu>
  }

  <ng-container *showOnDesktop>
    @if (!hideSolutionsTab) {
      <flow-menu-main-bookmark
        [extraClass]="'bookmark-visible'"
        [routerLink]="['/solutions']"
        [routerLinkActiveOptions]="{exact: true}"
        [label]="labels['account_settings.label.solutions']"
        [routerLinkActive]="'current-menu-item'"
        [icon]="'apps'">
      </flow-menu-main-bookmark>
    }
  </ng-container>

  @if (showShopTab) {
    <flow-menu-main-bookmark
      [extraClass]="'bookmark-visible'"
      [routerLink]="['/solutions', 'internal-shop']"
      [routerLinkActiveOptions]="{exact: true}"
      [label]="labels['general.label.internal_shop']"
      [routerLinkActive]="'current-menu-item'"
      [icon]="'add_shopping_cart'">
    </flow-menu-main-bookmark>
  }
</nav>

@if (!isMobile && isStandardEinvoicingProject) {
  <flow-menu-top />
}
