import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateFileExtension'
})
export class FlowTruncateFileExtensionPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const lastDotIndex = value.lastIndexOf('.');

    if (lastDotIndex === -1) {
      return '';
    }
    else {
      return value.substring(lastDotIndex + 1);
    }
  }
}
