import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlowComponentsModule, FlowMaterialModule, FlowPipesModule } from '@flow/shared';
import { FlowTranslateModule } from '@flow/translate';

import { FlowAdminOverviewLinkComponent } from './components/admin-overview-link/admin-overview-link.component';
import { FlowBookmarkTemplatesDropdownComponent } from './components/bookmark-templates-dropdown/bookmark-templates-dropdown.component';
import { FlowBoughtProductsDropdownComponent } from './components/bought-products-dropdown/bought-products-dropdown.component';
import { FlowBreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FlowSaveButtonComponent } from './components/save-button/save-button.component';
import { FlowTextBlockComponent } from './components/textblock/textblock.component';
import { FlowTextBlockTranslationsComponent } from './components/textblock-translations/textblock-translations.component';
import { FlowThemesDropdownComponent } from './components/themes-dropdown/themes-dropdown.component';

import { FlowWidgetSelectionComponent } from './modals/widget-selection/widget-selection.component';
import { FlowEinvoModalComponent } from './modals/einvo-modal/einvo-modal.component';
import { FlowModalCompanySearchComponent } from './modals/company-search/company-search.component';
import { FlowDmcPreloaderComponent } from './components/dmc-preloader/dmc-preloader.component';

import { FlowInterpolateHtmlDirective } from './directives/interpolate-html/interpolate-html.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlowMaterialModule,
    FlowPipesModule,
    FlowComponentsModule,
    FlowTranslateModule
  ],
  declarations: [
    FlowAdminOverviewLinkComponent,
    FlowBookmarkTemplatesDropdownComponent,
    FlowBoughtProductsDropdownComponent,
    FlowBreadcrumbComponent,
    FlowSaveButtonComponent,
    FlowTextBlockComponent,
    FlowTextBlockTranslationsComponent,
    FlowThemesDropdownComponent,
    FlowWidgetSelectionComponent,
    FlowEinvoModalComponent,
    FlowModalCompanySearchComponent,
    FlowDmcPreloaderComponent,
    FlowInterpolateHtmlDirective
  ],
  exports: [
    FlowAdminOverviewLinkComponent,
    FlowBookmarkTemplatesDropdownComponent,
    FlowBoughtProductsDropdownComponent,
    FlowBreadcrumbComponent,
    FlowSaveButtonComponent,
    FlowTextBlockComponent,
    FlowTextBlockTranslationsComponent,
    FlowThemesDropdownComponent,
    FlowDmcPreloaderComponent,
    FlowInterpolateHtmlDirective
  ]
})
export class FlowMarketplaceSharedModule {
}
