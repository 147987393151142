<div id="attachments-container">
  <div
    class="dropzone"
    [ngClass]="{
      'drag-over': uploadState.isOnDropzone && !uploadState.isUploadError,
      'upload-error': uploadState.isUploadError
    }"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (click)="fileInput.click()">
    <div class="info">
      <i class="material-icons">
        {{ !uploadState.isUploadError ? 'cloud_upload' : 'error' }}
      </i>
      <div class="title">
        <strong>
          {{
            !uploadState.isUploadError
            ? labels['supportform.title.upload-files']
            : uploadState.uploadErrors && uploadState.uploadErrors === 1
              ? labels['supportform.error.failed-upload']
              : labels['supportform.error.failed-uploads']
          }}
        </strong>
      </div>
      <ul *ngIf="uploadState.isUploadError && !uploadState.uploadErrorsMessage; else uploadErrorMessage">
        <li *ngFor="let fileName of uploadState.failedFileNames">
          {{ fileName | truncateFileName:50 }}
        </li>
      </ul>
      <ng-template #uploadErrorMessage>
        <div>{{ uploadState.uploadErrorsMessage }}</div>
      </ng-template>

      <div class="description">
        <div>{{ labels['supportform.description.upload-files'] }}</div>
        <div>
          <span>( {{ allowedFileExtensions }} {{ labels['supportform.label.format-accepted'] }} )</span>
          <span>&nbsp;( {{ maxUploadSizeFormatted }} {{ labels['supportform.label.maximum'] }} )</span>
        </div>
      </div>
    </div>

    <input
      id="dropzone-input"
      type="file"
      #fileInput
      (change)="onFileSelected($event)"
      (click)="onDropZoneClick()"
      multiple="true"
      [attr.accept]="allowedFileTypes"
      hidden>
  </div>

  <div class="preview-container">
    <div
      *ngFor="let file of files; let i = index"
      class="file-preview">
      <i
        class="material-icons file-type"
        [attr.aria-label]="file.name | truncateFileExtension">
        {{ file.type | fileIcon }}
      </i>
      <div class="description">
        <div class="name" [title]="file.name">{{ file.name | truncateFileName:23 }}</div>
        <div>{{ file.name | truncateFileExtension | fileType:labels }}</div>
        <div>{{ file.lastModified | dateLocale:'medium' }}</div>
      </div>
      <i
        class="material-icons remove"
        [attr.aria-label]="labels['supportform.aria-label.remove-file']"
        (click)="removeFile(i)"
      >close</i>
    </div>
  </div>
</div>
