<div class="p-3 modal-title bg-primary color-white">
  <h5
    class="truncate"
    [innerHTML]="labels['account_settings.label.change_account_password']"
  ></h5>
</div>
<mat-dialog-content>
  <ng-template #savingContainer>
    <div class="d-block p-4 text-center">
      <ng-container *ngIf="saveError; else saveSuccess">
        <i class="material-icons icon-3x">error</i>
        <p class="mt-2" [innerHTML]="labels['general.error.request_error']"></p>
      </ng-container>
      <ng-template #saveSuccess>
        <flow-preloader [completed]="saved"></flow-preloader>
        <p
          class="mt-2"
          *ngIf="saved"
          [innerHTML]="labels['msg.updated.password']"
        ></p>
      </ng-template>
    </div>
  </ng-template>

  <div class="row" *ngIf="!saving && !saved; else savingContainer">
    <div class="col-12 col-md-5">
      <div id="password-requirements">
        <div>
          <b [innerHTML]="labels['general.password_requirements.title'] | safe : 'html'"></b>
        </div>
        <ul>
          <li
            [ngClass]="{ 'color-success': passwordConformsTo('minChars') }"
            [innerHTML]="labels['general.password_requirements.rules.length'] | safe : 'html'"
          ></li>
          <li
            [ngClass]="{ 'color-success': passwordConformsTo('upperAndLowerChars') }"
            [innerHTML]="labels['general.password_requirements.rules.upper_lower_chars'] | safe : 'html'"
          ></li>
          <li
            [ngClass]="{ 'color-success': passwordConformsTo('numbers') }"
            [innerHTML]="labels['general.password_requirements.rules.numbers'] | safe : 'html'"
            ></li>
          <li
            [ngClass]="{ 'color-success': passwordConformsTo('specialChars') }"
            [innerHTML]="labels['general.password_requirements.rules.special_chars'] | safe : 'html'"
          ></li>
          <li
            [ngClass]="{ 'color-success': passwordConformsTo('noPersonalInfo')}"
            [innerHTML]="labels['general.password_requirements.rules.no_personal_info'] | safe : 'html'"
          ></li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-md-7">
      <form [formGroup]="form">
        <mat-form-field [appearance]="'fill'" class="mb-4">
          <mat-label>
            {{ labels['account_settings.label.current_password'] }}</mat-label>
          <input matInput type="password" formControlName="currentPassword" />
          <mat-error
            [hidden]="!form.get('currentPassword').invalid"
            [innerHTML]="
              form.get('currentPassword').hasError('required') &&
              !unknownPassword
                ? labels['generic.error.required']
                : form.get('currentPassword').hasError('required') &&
                  unknownPassword
                ? labels['generic.error.password_unknown']
                : form.get('currentPassword').hasError('invalidPassword')
                ? labels['generic.error.password_strength']
                : null
            "
          ></mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="'fill'" class="mb-4">
          <mat-label>{{ labels['account_settings.label.new_password'] }}</mat-label>
          <input matInput type="password" formControlName="newPassword" />
          <mat-error
            [hidden]="!form.get('newPassword').invalid"
            [innerHTML]="
              form.get('newPassword').hasError('required')
                ? labels['generic.error.required']
                : form.get('newPassword').hasError('passwordPersonal')
                ? labels['generic.error.password_has_personal_info']
                : form.get('newPassword').hasError('sameNewPassword')
                ? labels['generic.error.same_current_password']
                : form.get('newPassword').hasError('invalidPassword')
                ? labels['generic.error.password_strength']
                : null
            "
          ></mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="'fill'">
          <mat-label>{{ labels['account_settings.label.repeat_password'] }}</mat-label>
          <input matInput type="password" formControlName="repeatNewPassword" />
          <mat-error
            [hidden]="!form.get('repeatNewPassword').invalid"
            [innerHTML]="
              form.get('repeatNewPassword').hasError('required')
                ? labels['generic.error.required']
                : form.get('repeatNewPassword').hasError('passwordMatch')
                ? labels['generic.error.password_mismatch']
                : null
            "
          ></mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!saving || (saving && saved)">
  <button
    class="button button-close-dialogs"
    [mat-dialog-close]="false"
    [innerHTML]="labels['general.btn.close']"
  ></button>
  <button
    class="button button-primary ml-2"
    (click)="changePassword()"
    *ngIf="!saving && !saved"
    [disabled]="form.invalid"
    [innerHTML]="labels['account_settings.btn.change_password']"
  ></button>
</mat-dialog-actions>
