@if (isLoading$(); as loading) {
  @if (loading === 'error') {
    <flow-alert-message
      [type]="'danger'"
      [messageId]="'error.general.error_loading'"
      [extraClasses]="alertMessageClassNames"
    />
  } @else {
    <div [ngClass]="loadingWrapperClassNames">
      <flow-preloader />
    </div>
  }
}