import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { QuillModule } from 'ngx-quill';
import { ColorPickerModule } from '@iplab/ngx-color-picker';

import { FlowMaterialModule } from '../material/material.module';
import { FlowDirectivesModule } from '../directives/directives.module';
import { FlowPipesModule } from '../pipes/pipes.module';

import { FlowAlertMessageComponent } from './alert-message/alert-message.component';
import { FlowGravatarComponent } from './gravatar/gravatar.component';
import { FlowPortalLogoComponent } from './portal-logo/portal-logo.component';
import { FlowAbsPreloaderComponent } from './abs-preloader/abs-preloader.component';
import { FlowPreloaderComponent } from './preloader/preloader.component';
import { FlowLoadingOrErrorComponent } from './loading-or-error/loading-or-error.component';
import { FlowLogoComponent } from './flow-logo/flow-logo.component';
import { FlowTranslateComponent } from './translate-button/translate-button.component';
import { FlowJsonViewerComponent } from './json-viewer/json-viewer.component';
import { FlowColorPickerComponent } from './color-picker/color-picker.component';

// Forms
import { FlowExtrasFormComponent } from './forms/extras/extras.component';
import { FlowFieldTranslateComponent } from './forms/field-translate/field-translate.component';
import { FlowCountriesDropdownComponent } from './forms/countries-dropdown/countries-dropdown.component';
import { FlowInputEmailComponent } from './forms/input-email/input-email.component';
import { FlowInputNameOfPersonComponent } from './forms/input-name-of-person/input-name-of-person.component';
import { FlowInputPhoneNumberComponent } from './forms/input-phone-number/input-phone-number.component';
import { FlowLanguagesDropdownComponent } from './forms/languages-dropdown/languages-dropdown.component';
import { FlowProvincesDropdownComponent } from './forms/provinces-dropdown/provinces-dropdown.component';
import { FlowRepeatedFormComponent } from './forms/repeated/repeated.component';
import { FlowRepeatedCollapsibleFormComponent } from './forms/repeated-collapsible/repeated-collapsible.component';
import { FlowSelectMultipleAutocompleteComponent } from './forms/select-multiple-autocomplete/select-multiple-autocomplete.component';
import { FlowTimepickerComponent } from './forms/timepicker/timepicker.component';

export const flowSharedImports = [
  CommonModule,
  QuillModule.forRoot({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ 'color': [] }],
        [{ align: [] }],
        ['clean'],
        ['link', 'image'],
      ],
    },
    bounds: document.body,
    debug: 'warn',
    theme: 'snow',
    placeholder: '...',
    readOnly: false,
  }),
  ReactiveFormsModule,
  FlowMaterialModule,
  FlowDirectivesModule,
  FlowPipesModule,
  ColorPickerModule,
];

@NgModule({
  imports: [flowSharedImports],
  declarations: [
    FlowAlertMessageComponent,
    FlowGravatarComponent,
    FlowPortalLogoComponent,
    FlowPreloaderComponent,
    FlowAbsPreloaderComponent,
    FlowLoadingOrErrorComponent,
    FlowExtrasFormComponent,
    FlowFieldTranslateComponent,
    FlowCountriesDropdownComponent,
    FlowInputEmailComponent,
    FlowInputNameOfPersonComponent,
    FlowInputPhoneNumberComponent,
    FlowLanguagesDropdownComponent,
    FlowProvincesDropdownComponent,
    FlowRepeatedFormComponent,
    FlowRepeatedCollapsibleFormComponent,
    FlowSelectMultipleAutocompleteComponent,
    FlowTimepickerComponent,
    FlowLogoComponent,
    FlowTranslateComponent,
    FlowJsonViewerComponent,
    FlowColorPickerComponent,
  ],
  exports: [
    FlowAlertMessageComponent,
    FlowGravatarComponent,
    FlowPortalLogoComponent,
    FlowPreloaderComponent,
    FlowExtrasFormComponent,
    FlowFieldTranslateComponent,
    FlowCountriesDropdownComponent,
    FlowInputEmailComponent,
    FlowInputNameOfPersonComponent,
    FlowInputPhoneNumberComponent,
    FlowLanguagesDropdownComponent,
    FlowProvincesDropdownComponent,
    FlowAbsPreloaderComponent,
    FlowLoadingOrErrorComponent,
    FlowRepeatedFormComponent,
    FlowRepeatedCollapsibleFormComponent,
    FlowSelectMultipleAutocompleteComponent,
    FlowTimepickerComponent,
    FlowLogoComponent,
    FlowTranslateComponent,
    FlowJsonViewerComponent,
    FlowColorPickerComponent,
    QuillModule,
    ColorPickerModule,
  ],
})
export class FlowComponentsModule {}
