/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { Observable, of, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import {
  FlowHelpers,
  FlowModelsService,
  FlowRegexService,
  FlowUtilsService,
  FlowBookmarkTemplateInterface,
  FlowBookmarkInterface,
  FlowPortalThemeInterface,
  FlowUserDashboardInterface,
  FlowVendorDashboardInterface,
  FlowTextBlockModelInterface,
  KinetixExtraModel
} from '@flow/core';

import { FlowAuthService, FlowUserService } from '@flow/auth';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

@Injectable({
  providedIn: 'root'
})
export class FlowMarketplaceDashboardService {

  // Used for caching widget translations
  private widgetTranslations: any[];

  private currentUserDashboard: FlowUserDashboardInterface;

  private currentVendorDashboard: FlowVendorDashboardInterface;

  private currentProjectDashboard: FlowVendorDashboardInterface;

  constructor(
    private ModelsService: FlowModelsService,
    private RegexService: FlowRegexService,
    private UtilsService: FlowUtilsService,
    private AuthService: FlowAuthService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService
  ) { }

  clear() {
    this.currentVendorDashboard = null;
    this.currentUserDashboard = null;
    this.currentProjectDashboard = null;
  }

  setUserDashboard(input: FlowUserDashboardInterface) {
    this.currentUserDashboard = input;
  }

  setVendorDashboard(input: FlowVendorDashboardInterface) {
    this.currentVendorDashboard = input;
  }

  setProjectDashboard(input: FlowVendorDashboardInterface) {
    this.currentProjectDashboard = input;
  }

  getUserDashboard(): FlowUserDashboardInterface {
    return this.currentUserDashboard;
  }

  getVendorDashboard(): FlowVendorDashboardInterface {
    return this.currentVendorDashboard;
  }

  getProjectDashboard(): FlowVendorDashboardInterface {
    return this.currentProjectDashboard;
  }

  getUserWidgets(): any[] {
    return this.currentUserDashboard.widgets;
  }

  setUserWidgets(widgets: any[]): void {
    this.currentUserDashboard.widgets = widgets;
  }

  getVendorWidgets(): any[] {
    return this.currentVendorDashboard.widgets;
  }

  setVendorWidgets(widgets: any[]): void {
    this.currentVendorDashboard.widgets = widgets;
  }

  /**
   * If admin user has assigned bookmark template to all users then disable
   * default option in bookmark template dropdown.
   * Only used in profile form.
   */
  disableDefaultBookmarkTemplate(): boolean {
    const { selectedBookmarkTemplate } = !this.UserService.isActingAsPartner()
                                         ? this.currentVendorDashboard
                                         : this.currentProjectDashboard.partners;

    return !!(selectedBookmarkTemplate && selectedBookmarkTemplate !== 'default');
  }

  /**
   * If a bookmark template is selected for a user (by himself -> my profile or by his company admin -> user management)
   * return those bookmarks.
   * Otherwise if the selected bookmark template for the company users is not the default one, return those bookmarks.
   */
  getUserBookmarks(): {templateId: string; bookmarks: FlowBookmarkInterface[]} | null {
    const userBookmarkTemplate = this.UserService.getExtraValue('FlowBookmarksProfile');

    if (userBookmarkTemplate && userBookmarkTemplate !== 'default') {
      const bookmarkTemplate = this.currentVendorDashboard.bookmarkTemplates.find(template => template.id === userBookmarkTemplate);

      if (bookmarkTemplate) {
        return {
          templateId: bookmarkTemplate.id,
          bookmarks: bookmarkTemplate.bookmarks
        };
      }
    }
    else {
      if (this.currentVendorDashboard.selectedBookmarkTemplate && this.currentVendorDashboard.selectedBookmarkTemplate !== 'default') {
        const bookmarkTemplate = this.currentVendorDashboard.bookmarkTemplates
          .find(template => template.id === this.currentVendorDashboard.selectedBookmarkTemplate);

        if (bookmarkTemplate) {
          return {
            templateId: bookmarkTemplate.id,
            bookmarks: bookmarkTemplate.bookmarks
          };
        }
      }
    }

    return null;
  }

  /**
   * If a bookmark template is selected for a user (by himself -> my profile or by his company admin -> user management)
   * return those bookmarks.
   * Otherwise if the selected bookmark template for partners is not the default one, return those bookmarks.
   */
  getProjectBookmarks(): {templateId: string; bookmarks: FlowBookmarkInterface[]} | null {
    const userBookmarkTemplate = this.UserService.getExtraValue('FlowBookmarksProfile');

    if (userBookmarkTemplate && userBookmarkTemplate !== 'default') {
      const bookmarkTemplate = this.currentProjectDashboard.bookmarkTemplates
        .find(template => template.id === userBookmarkTemplate);

      if (bookmarkTemplate) {
        return {
          templateId: bookmarkTemplate.id,
          bookmarks: bookmarkTemplate.bookmarks
        };
      }
    }
    else {
      if (this.currentProjectDashboard.partners.selectedBookmarkTemplate && this.currentProjectDashboard.partners.selectedBookmarkTemplate !== 'default') {
        const bookmarkTemplate = this.currentProjectDashboard.bookmarkTemplates
          .find(template => template.id === this.currentProjectDashboard.partners.selectedBookmarkTemplate);

        if (bookmarkTemplate) {
          return {
            templateId: bookmarkTemplate.id,
            bookmarks: bookmarkTemplate.bookmarks
          };
        }
      }
    }

    return null;
  }

  getWidgetSubTypeValueFromExtra(widgetSubType: string, widgetExtra: KinetixExtraModel[]): string|undefined {
    const subTypeExtra = this.UtilsService.getExtra(widgetExtra, widgetSubType, 'fieldName');

    if (subTypeExtra) {
      const { value } = subTypeExtra;

      return value || undefined;
    }

    return undefined;
  }

  /**
   * Get widget translations for all flow and customer widgets
   */
  getWidgetTranslations(): Observable<any[]> {
    if (this.widgetTranslations) {
      return of(this.widgetTranslations);
    } else {
      const vendor = this.AuthService.vendor;

      const getTranslatePattern = (platform: string) => {
        const guidPattern = this.RegexService.getPattern('guid');

        // Example
        // ^flow.kinetixsimplewidget.(({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}).(name|description)$
        return `^${platform}.kinetixsimplewidget.(${guidPattern}).(name|description)$`;
      };

      return forkJoin([
        this.ModelsService.list<FlowTextBlockModelInterface>('TextBlock', {
          query: `{ "_id": { "$regex": "${getTranslatePattern('flow')}" }}`,
          platform: 'Flow'
        }),
        this.ModelsService.list<FlowTextBlockModelInterface>('TextBlock', {
          query: `{ "_id": { "$regex": "${getTranslatePattern(vendor)}" }}`,
          platform: vendor
        })
      ])
      .pipe(
        tap(([flowWidgetTranslations, vendorWidgetTranslations]) => {
          this.widgetTranslations = [...flowWidgetTranslations.data, ...vendorWidgetTranslations.data];
        }),
        catchError(() => of([]))
      );
    }
  }

  /**
   * Fetch translated bookmark labels
   */
  fetchBookmarksLabels(templateId: string, bookmarks: any): Observable<FlowTranslateLabel> {
    const translationIds = [];
    const platform = this.UserService.isActingAsPartner()
      ? this.AuthService.vendor.toLowerCase()
      : this.UserService.company.toLowerCase();

    // Collect translation ids for assigned bookmarks
    bookmarks.forEach(bookmark => {
      // ST030691
      // There was an issue of not translating bookmark template items although translations were correctly set.
      // Reason for this was a product id like "dmOrders" that was assigned to a bookmark template.
      // As all translations are stored with lowercased ids ("dmorders") the related translation was never found,
      // which in turn always will default to the name attribute of the bookmark.item.
      translationIds.push(`${platform}.bookmarktemplates.${templateId}.${bookmark.id.toLowerCase()}.name`);
    });

    return this.TranslateService.fetchLabels(translationIds, {
      platform: FlowHelpers.vendorName(platform),
      bypassCache: true
    });
  }


  /**
   * Add a new bookmark template to the vendor dashboard
   */
  addBookmarkTemplate(template: FlowBookmarkTemplateInterface): void {
    this.currentVendorDashboard.bookmarkTemplates.push(template);
  }

  /**
   * Get a bookmark template from the vendor dashboard by id.
   */
  getBookmarkTemplate(id: string): FlowBookmarkTemplateInterface {
    return this.currentVendorDashboard.bookmarkTemplates.find(template => template.id === id);
  }

  /**
   * Update a bookmark template from the vendor dashboard.
   */
  updateBookmarkTemplate(template: FlowBookmarkTemplateInterface) {
    const tmplIndex = this.currentVendorDashboard.bookmarkTemplates.findIndex(tmpl => tmpl.id === template.id);

    if (tmplIndex > -1) {
      this.currentVendorDashboard.bookmarkTemplates[tmplIndex] = template;
    }
  }

  /**
   * Remove a bookmark template from the vendor dashboard.
   */
  removeBookmarkTemplate(id: string): void {
    const tmplIndex = this.currentVendorDashboard.bookmarkTemplates.findIndex(tmpl => tmpl.id === id);

    if (tmplIndex > -1) {
      this.currentVendorDashboard.bookmarkTemplates.splice(tmplIndex, 1);
    }
  }

  /**
   * Add a new portal theme to the vendor dashboard
   */
  addPortalTheme(theme: any): void {
    this.currentVendorDashboard.themes.push({
      name: theme.name,
      slug: FlowHelpers.slugify(theme.name),
      thumbnailUrl: '',
      thumbnailUrlV2: '',
      colors: btoa(JSON.stringify(theme.colors))
    });
  }

  /**
   * Get a portal theme from the vendor dashboard by slug.
   */
  getPortalTheme(slug: string): FlowPortalThemeInterface {
    const theme = this.currentVendorDashboard.themes.find(theme => theme.slug === slug);

    if (theme) {
      try {
        const colors = JSON.parse(atob(theme.colors));

        return {
          ...theme,
          colors
        };
      }
      catch (error) {
        return theme;
      }
    }

    return null;
  }

  /**
   * Get available portal themes from the vendor dashboard
   */
  getPortalThemes(): FlowPortalThemeInterface[] {
    return this.currentVendorDashboard.themes || [];
  }

  /**
   * Update a portal theme from the vendor dashboard.
   */
  updatePortalTheme(slug: string, updatedTheme: any) {
    const themeIndex = this.currentVendorDashboard.themes.findIndex(theme => theme.slug === slug);

    if (themeIndex > -1) {
      this.currentVendorDashboard.themes[themeIndex] = {
        name: updatedTheme.name,
        slug: FlowHelpers.slugify(updatedTheme.name),
        thumbnailUrl: '',
        thumbnailUrlV2: '',
        colors: btoa(JSON.stringify(updatedTheme.colors))
      };
    }
  }

  /**
   * Remove a portal theme from the vendor dashboard.
   */
  removePortalTheme(slug: string): void {
    const themeIndex = this.currentVendorDashboard.themes.findIndex(theme => theme.slug === slug);

    if (themeIndex > -1) {
      this.currentVendorDashboard.themes.splice(themeIndex, 1);
    }
  }

  /**
   * Return solutions id based on string in document type in
   * document manager URL or key property and bought product
   * to enable activated bookmark tabs.
   */
  getEinvoFilteredSolutionsId(params: { url: string; key: string }): string {
    const hasDmInvoic = this.UserService.isBoughtProduct('dminvoic');
    const hasDmOrdrsp = this.UserService.isBoughtProduct('dmordrsp');
    const hasDmOrders = this.UserService.isBoughtProduct('dmOrders');
    const hasDmTimesheet = this.UserService.isBoughtProduct('dmtimesheet');
    const hasEinvo = this.UserService.isBoughtProduct('einvo');

    let solutionsId;

    if (
      (
        (params.url && params.url.includes('-invoice'))
        ||
        (params.key && params.key === 'invoice')
      )
      &&
      hasDmInvoic) {
      solutionsId = 'dminvoic';
    }
    else if (
      (
        (params.url && params.url.includes('-orderack-'))
        ||
        (params.key && params.key === 'orderack')
      )
      &&
      hasDmOrdrsp) {
      solutionsId = 'dmordrsp';
    }
    else if (
      (
        (params.url && params.url.includes('-order-'))
        ||
        (params.key && params.key === 'order')
      )
      &&
      hasDmOrders) {
      solutionsId = 'dmOrders';
    }
    else if (
      (
        (params.url && params.url.includes('-timesheet-'))
        ||
        (params.key && params.key === 'timesheet')
      )
      &&
      hasDmTimesheet) {
      solutionsId = 'dmtimesheet';
    }
    else if (hasEinvo) {
      solutionsId = 'einvo';
    }
    else {
      solutionsId = '';
    }

    return solutionsId;
  }
}
