import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlowEnvService } from '@flow/core';
import { FlowLayoutService } from '@flow/shared';

@Component({
  selector: 'flow-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowContentComponent implements OnInit, OnDestroy {
  @Input() isChatOpen: boolean;

  @HostBinding('class') get className() {
    return this.isChatOpen ? 'chat-opened' : null;
  }

  isMobile: boolean;
  isStandardEinvoicingProject: boolean;

  private _destroy$$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private EnvService: FlowEnvService,
    private LayoutService: FlowLayoutService
  ) {}

  ngOnInit(): void {
    // Check device
    this.LayoutService.isMobile$
      .pipe(
        takeUntil(this._destroy$$)
      )
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectorRef.markForCheck();
      });

    this.isStandardEinvoicingProject = this.EnvService.isStandardEinvoicingProject;
  }

  ngOnDestroy(): void {
    this._destroy$$.next();
    this._destroy$$.complete();
  }
}
