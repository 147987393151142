import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  FlowHelpers,
  FlowEnvService,
  FlowVendorDashboardInterface,
  FlowUrlViewInterface,
  FlowGoogleStorageService,
  FlowTextBlockModelInterface
} from '@flow/core';
import { FlowAuthService, FlowUserService } from '@flow/auth';
import { FlowTranslateService } from '@flow/translate';

import cssVars from 'css-vars-ponyfill';

import { FlowMediaService } from '../media/media.service';
import { DEFAULT_COLOR_SCHEMA } from './constants/default-color-schema';


@Injectable({
  providedIn: 'root'
})
export class FlowPortalService {

  private _defaultColorSchema = DEFAULT_COLOR_SCHEMA;

  /**
   * Application configuration.
   * Stores the configuration based on the subdomain in which FLOW is running.
   */
  private _appConfiguration: any = {
    dashboard: {},
    colorSchema: {
      ...this._defaultColorSchema
    },
    themeVars: {}
  };

  private _portalLogo: string;

  private _portalBackground: string;

  private _welcomeTitle: string;

  private _browserTitle: string;

  private _portalDescription: string;

  private _currentDomainTerms: FlowTextBlockModelInterface;

  constructor(
    private title: Title,
    private EnvService: FlowEnvService,
    private GoogleStorageService: FlowGoogleStorageService,
    private AuthService: FlowAuthService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private MediaService: FlowMediaService
  ) { }

  /** Getter for the default color schema. */
  get defaultColorSchema(): any {
    return this._defaultColorSchema;
  }

  /** Getter for the current application dashboard. */
  get currentPortalDashboard(): FlowVendorDashboardInterface {
    return this._appConfiguration.dashboard;
  }

  /** Gets the current application title. */
  get welcomeTitle(): string {
    return this._welcomeTitle;
  }

  get portalDescription(): string {
    return this._portalDescription;
  }

  get logo(): string {
    return this._portalLogo;
  }

  get background(): string {
    return this._portalBackground;
  }

  get portalTerms(): FlowTextBlockModelInterface {
    return this._currentDomainTerms;
  }

  get exclusiveMode(): boolean {
    return this.currentPortalDashboard.partners.exclusiveMode;
  }

  get activeTheme(): string {
    return this._appConfiguration.dashboard.theme || 'default';
  }

  getPortalThemeColor(colorKey = 'primary'): string {
    return this._appConfiguration.colorSchema[colorKey] || this._appConfiguration.colorSchema.primary;
  }

  setPortal(): Promise<void> {
    this.EnvService.logWarn('setting portal domain ...');
    this.EnvService.setPortalDomain();
    this.EnvService.logDebug(this.EnvService.portalDomain);

    this.EnvService.logWarn('setting portal url prefix ...');
    this.EnvService.setPortalPrefix();
    this.EnvService.logDebug(this.EnvService.portalPrefix);

    this.EnvService.setBodyId();

    // Set the portal background URL and the logo.
    this._portalBackground = this.MediaService.getVendorPortalBackground(this.EnvService.portalDomain);

    this._portalLogo = this.MediaService.getVendorLogoUrl(this.EnvService.portalDomain);

    return forkJoin([
      this.GoogleStorageService.getVendorDashboard(this.EnvService.portalDomain),
      this.TranslateService.getTextBlockById('terms.legal', { platform: this.EnvService.portalDomain })
    ]).pipe(
      tap(data => {
        this._appConfiguration.dashboard = data[0];

        this._currentDomainTerms = data[1];

        this.setWelcomeTitle();

        this.setBrowserTitle();

        this.setPortalDescription();

        this.setPortalTheme();

        /** Polyfill for IE 11 */
        cssVars({
          include: 'style',
          onlyLegacy: true,
          watch: true,
          variables: this._appConfiguration.themeVars
        });

        this.EnvService.logWarn('portal configuration set to ...');
        this.EnvService.logDebug(this._appConfiguration);
      }),
      map(() => null)
    ).toPromise();
  }

  /**
   * Based on the :root-selector in @flow/assets, set the variables.
   */
  applyPortalTheme(): void {
    Object.keys(this._appConfiguration.colorSchema).forEach(key => {
      this._appConfiguration.themeVars[`--${key}`] = this._appConfiguration.colorSchema[key];
      document.documentElement.style.setProperty(`--${key}`, this._appConfiguration.colorSchema[key]);
    });
  }

 /**
  * Set portal theme
  *
  * @param colors?
  * If colors are not passed, the portal theme is set based on the active theme.
  * If colors are passed, the portal theme is set temporarily for a preview.
  */
  setPortalTheme(colors?: any): void {
    if (!colors) {
      // Check active theme
      if (this.activeTheme === 'default') {
        colors = this.defaultColorSchema;
      }
      else {
        const foundTheme = this.currentPortalDashboard.themes.find(theme => theme.slug === this.activeTheme);

        if (!foundTheme) {
          colors = this.defaultColorSchema;
        }
        else {
          try {
            colors = JSON.parse(atob(foundTheme.colors));
          }
          catch (error) {
            colors = this.defaultColorSchema;
           }
        }
      }
    }

    if (!FlowHelpers.isEmptyObject(colors)) {
      Object.keys(colors).forEach(key => {
        this._appConfiguration.colorSchema[key] = colors[key];
      });

      this.applyPortalTheme();
    }
  }

  /**
   * Set portal theme for partners
   * As setPortalTheme() is called on init for the project before setPortalThemeForPartners()
   * is called in app.component, there's no need to treat any default cases, aka the project
   * theme will be used.
   */
  setPortalThemeForPartners(): void {
    const partnerTheme = this._appConfiguration.dashboard.partners.theme;

    if (partnerTheme && partnerTheme !== 'default') {
      const foundTheme = this.currentPortalDashboard.themes.find(theme => theme.slug === partnerTheme);
      let colors;

      if (foundTheme) {
        try {
          colors = JSON.parse(atob(foundTheme.colors));
        }
        catch (error) {
          console.log(error);
        }

        if (!FlowHelpers.isEmptyObject(colors)) {
          Object.keys(colors).forEach(key => {
            this._appConfiguration.colorSchema[key] = colors[key];
          });

          this.applyPortalTheme();
        }
      }
    }
  }

  /**
   * Set welcome title displayed in the login page
   */
  setWelcomeTitle(): void {
    this._welcomeTitle = this.TranslateService.getTranslationFromTextBlock(this._appConfiguration.dashboard.partners.welcomeTitle);

    if (!this._welcomeTitle) {
      this._welcomeTitle = 'login.login_form.headline';
    }
  }

  /**
   * Set the browser title
   */
  setBrowserTitle(): void {
    this._browserTitle = this.TranslateService.getTranslationFromTextBlock(this._appConfiguration.dashboard.partners.browserTitle);

    if (!this._browserTitle) {
      this._browserTitle = 'FLOW by SPS Commerce';
    }

    this.title.setTitle(FlowHelpers.trimHTml(this._browserTitle));
  }

  /**
   * Set portal description displayed in the login page
   */
  setPortalDescription(): void {
    this._portalDescription = this.TranslateService.getTranslationFromTextBlock(this._appConfiguration.dashboard.partners.portalDescription);
  }

  /** Gets the current user portal url */
  getPortalUrl(vendor: string): string {
    vendor = vendor.toLowerCase();

    if ('flow' === vendor) {
      return `https://${this.EnvService.portalPrefix}.tiekinetix.net`;
    }

    return `https://${vendor}.${this.EnvService.portalPrefix}.tiekinetix.net`;
  }

  /**
   * Get a custom redirect url after login.
   * Defaults to this.AuthService.afterLoginUrl
   */
  getCustomRedirectUrl(): string[] {
    if (this.AuthService.isMarketplace) {
      const redirectStatePath = this.AuthService.redirectStatePath;
      const partnersHomepage = this.currentPortalDashboard.partners.homepage;

      // If redirectStatePath is available (set when non-authenticated user calls any portal url), redirect to it.
      if (redirectStatePath) {
        return [redirectStatePath];
      }

      // A vendor has configured a default homepage for the partners that access through the vendor portal
      if (partnersHomepage
          &&
          this.UserService.isActingAsPartner()
          &&
          this.AuthService.vendor === this.EnvService.portalDomain) {
        if (!FlowHelpers.isEmptyObject(partnersHomepage)) {
          return ['/solutions', partnersHomepage['id']];
        }
        else if (typeof partnersHomepage === 'string') {
          return ['/solutions', partnersHomepage];
        }
      }
      // A user has configured a default homepage
      else {
        const userHomepage = this.UserService.getExtraValue('AutoLoadProduct');

        if (userHomepage) {
          const homepageParts = userHomepage.split(',');

          // Because of a former wrong implementation of storing AutoLoadProduct in V2
          // this value can be like "einvo".
          // In V1 this value can be like "exchange,my-manual-documents,einvo"
          return ['/solutions', homepageParts[homepageParts.length - 1]];
        }
      }
    }

    return this.AuthService.afterLoginUrl;
  }

  /**
   * Get url, label and logo for login via SSO
   */
  getSsoLoginLink(): {url: string; label: string; logo: string} {
    if (this.currentPortalDashboard) {
      const { sso } = this.currentPortalDashboard;

      if (sso) {
        const { link, logo, translations } = sso;

        if (link && translations) {
          const userLanguage = this.TranslateService.getCurrentLanguage();

          return {
            url: link,
            label: translations[userLanguage]
                   ? translations[userLanguage]
                   : translations['en-GB']
                     ? translations['en-GB']
                     : 'Login via SURFconext',
            logo: logo ? logo : ''
          };
        }
      }
    }

    return null;
  }

  /**
   * Convenience method to get the customer privacy settings
   */
  getCustomerPrivacySettings(): FlowUrlViewInterface|undefined {
    return this._getCustomerPrivacyTermsSettings('privacy');
  }

  /**
   * Convenience method to get the customer terms & conditions settings
   */
  getCustomerTermsSettings(): FlowUrlViewInterface|undefined {
    return this._getCustomerPrivacyTermsSettings('termsConditions');
  }

  /**
   * Method to get the customer privacy or terms & conditions settings
   * with the url in the current language
   */
  private _getCustomerPrivacyTermsSettings(settingsType: string): FlowUrlViewInterface|undefined {
    const projectDashboard = this.currentPortalDashboard;
    const settings = projectDashboard[settingsType];

    if (settings) {
      const { url, view } = settings;

      if (url && view) {
        // Allow backwards compatibility as url was stored initially as single value
        if (typeof url === 'string') {
          return { url, view };
        }
        // Url needs to be stored now language based as object
        else if (FlowHelpers.isObject(url) && !FlowHelpers.isEmptyObject(url)) {
          const userLanguage = this.TranslateService.getCurrentLanguage();
          const langBasedUrl = url[userLanguage];

          if (langBasedUrl) {
            return { url: langBasedUrl, view };
          }
        }
      }
    }

    return undefined;
  }
}
