<flow-support-widget
  *ngIf="showSupportWidget$ | async"
  [config]="widgetAttributes.config"
  [labels]="widgetAttributes.labels"
  [layout]="widgetAttributes.layout"
  [options]="widgetAttributes.options"
  [tokens]="widgetAttributes.tokens"
></flow-support-widget>

<!-- <flow-help-zendesk
  [label]="'Help'"
  [customHelpSiteUrl]="customHelpSiteUrl"
  [customHelpSiteInModal]="customHelpSiteInModal"
  [products]="products$ | async"
  [projectExtras]="projectExtras$ | async">
</flow-help-zendesk> -->

<!-- comment out because the customHelpSite is now inject as a btn inside the zendesk iframe itself
<flow-help-custom-site
  *ngIf="hasCustomHelpSite"
  [label]="labels['general.label.help']"
  [settings]="dashboard.customHelpSite">
</flow-help-custom-site>
-->


